import vtkActor from "@kitware/vtk.js/Rendering/Core/Actor";
import vtkMath from "@kitware/vtk.js/Common/Core/Math";
import vtkTexture from "@kitware/vtk.js/Rendering/Core/Texture";

import { mat4, vec3 } from "gl-matrix";

import { IMeshNode, MarginLine } from "@envistaco/dicom-reader";
import { MeshName, SelectableMesh } from "../utils/common";
import { createMeshActor } from "../utils/vtkUtils";
import uuid from "react-uuid";
import vtkDataArray from "@kitware/vtk.js/Common/Core/DataArray";
import vtkPolyData from "@kitware/vtk.js/Common/DataModel/PolyData";

export enum RenderingMode {
  Colored,
  TrueColor,
  Scalar,
}
const isUpperJaw = (name: string) => {
  return name.includes(MeshName.Maxillary);
};

const isLowerJaw = (name: string) => {
  return name.includes(MeshName.Mandibular);
};

const rgbToHex = (r: number, g: number, b: number): string => {
  const red = Math.round(r * 255)
    .toString(16)
    .padStart(2, "0");
  const green = Math.round(g * 255)
    .toString(16)
    .padStart(2, "0");
  const blue = Math.round(b * 255)
    .toString(16)
    .padStart(2, "0");
  return `#${red}${green}${blue}`;
};

abstract class Mesh {
  id: string;
  name: string;
  mesh: IMeshNode;
  scanId: string;
  opacity: number;

  visible: boolean;
  checked: boolean;

  color: boolean;
  light: boolean;

  actor: vtkActor;
  texture: vtkTexture;
  textureScalars: vtkDataArray;

  rotation: vec3;
  movement: vec3;

  matrix: mat4;

  preferColor = { r: 0, g: 0, b: 0 };
  preferColorHex: string;

  isActive: boolean;

  constructor(
    name: string,
    mesh: IMeshNode,
    scanId: string,
    preferColor?: number[]
  ) {
    this.id = uuid();
    this.name = name;
    this.scanId = scanId;
    this.rotation = [0, 0, 0];
    this.movement = [0, 0, 0];
    this.mesh = mesh;
    this.opacity = 100;
    this.visible = true;
    this.checked = false;
    this.color = true;
    this.light = true;
    this.actor = {} as vtkActor;
    this.texture = {} as vtkTexture;
    this.textureScalars = {} as vtkDataArray;
    this.matrix = new Float32Array();
    this.preferColor = {
      r: preferColor ? preferColor[0] : 200 / 255.0,
      g: preferColor ? preferColor[1] : 200 / 255.0,
      b: preferColor ? preferColor[2] : 200 / 255.0,
    };
    this.preferColorHex = rgbToHex(
      this.preferColor.r,
      this.preferColor.g,
      this.preferColor.b
    );
    this.isActive = true;
  }

  abstract build(): void;

  isSelectable = () => {
    return SelectableMesh.includes(this.name as MeshName);
  };

  isCommonScanMesh = () => {
    return this.name === "Maxillary" || this.name === "Mandibular";
  };

  isEdentulousScanMesh = () => {
    return this.name.includes("Edentulous");
  };

  isUpperJaw = () => {
    return isUpperJaw(this.name);
  };

  isLowerJaw = () => {
    return isLowerJaw(this.name);
  };

  isCommonUpperJaw = () => {
    return (
      MeshName.Maxillary === this.name ||
      MeshName.MaxillaryAnatomy === this.name
    );
  };

  isCommonLowerJaw = () => {
    return (
      MeshName.Mandibular === this.name ||
      MeshName.MandibularAnatomy === this.name
    );
  };

  hasScalar() {
    if (!this.actor) {
      return false;
    }
    const polydata = this.actor.getMapper()?.getInputData();
    if (!polydata) {
      return false;
    }
    const scalars = polydata.getPointData().getScalars();
    if (!scalars || !scalars.getData() || scalars.getNumberOfValues() <= 0) {
      return false;
    }
    return true;
  }

  setVisible = (visible: boolean) => {
    this.visible = visible;
    if (this.actor) {
      this.actor.setVisibility(visible);
    }
  };

  setOpacity = (arg: number) => {
    if (null == this.actor) {
      return;
    }
    let op = arg / 100.0;
    this.actor.getProperty().setOpacity(op);
    // this.setBackface(this.isBackfaceAvailable());
  };

  switchLight = (light: boolean) => {
    if (this.actor) {
      this.actor.getProperty().setAmbient(light ? 0.0 : 1.0);
      this.actor.getProperty().setDiffuse(light ? 1.0 : 0.0);
      this.actor.getProperty().setSpecular(light ? 0.0 : 0.3);
      this.actor.getProperty().setSpecularPower(light ? 0 : 128);
    }
  };

  switchTexture = (textureOn: boolean) => {
    if (this.texture != null) {
      textureOn ? this.revertTexture() : this.removeTexture();
    }
  };

  setBackface(enabled: boolean) {
    // setActorBackface(this.actor, enabled);
  }

  revertTexture = () => {
    if (this.actor) {
      this.actor.addTexture(this.texture);
      this.setBackface(this.isBackfaceAvailable());
    }
  };

  removeTexture = () => {
    if (this.actor) {
      this.actor.removeTexture(this.texture);
      this.setBackface(this.isBackfaceAvailable());
    }
  };

  hasTrueColor = () => {
    return this.hasTexture() || this.hasTextureScalars();
  };

  hasTexture = () => {
    return this.texture != null;
  };

  hasTextureScalars = () => {
    return this.textureScalars != null;
  };

  isTransparented() {
    if (this.actor) {
      const op = this.actor.getProperty().getOpacity();
      if (op < 1) {
        return true;
      }
    }
    return false;
  }

  isBackfaceAvailable() {
    if (this.actor && this.texture && this.actor.hasTexture(this.texture)) {
      return false;
    }
    if (this.isTransparented()) {
      return false;
    }
    return true;
  }

  // for orientation adjustment
  rotateX(arg: any) {
    if (this.actor == null) {
      return;
    }
    this.rotation[0] = arg;
    this.doTransform();
  }

  rotateY(arg: any) {
    if (this.actor == null) {
      return;
    }
    this.rotation[1] = arg;
    this.doTransform();
  }

  rotateZ(arg: any) {
    if (this.actor == null) {
      return;
    }
    this.rotation[2] = arg;
    this.doTransform();
  }

  doMovement(arg: any) {
    if (this.actor == null) {
      return;
    }
    this.movement[2] = arg;
    this.doTransform();
  }

  doTransform() {
    const m = mat4.create();
    mat4.rotateX(m, m, vtkMath.radiansFromDegrees(this.rotation[0]));
    mat4.rotateY(m, m, vtkMath.radiansFromDegrees(this.rotation[1]));
    mat4.rotateZ(m, m, vtkMath.radiansFromDegrees(this.rotation[2]));
    mat4.translate(m, m, this.movement);
    this.actor.setUserMatrix(m);
    this.matrix = m;
  }

  doTransformWithMatrix(matrix: mat4) {
    let m = matrix;
    mat4.transpose(m, matrix);
    this.actor.setUserMatrix(m);
  }

  hasAdjustments() {
    const x = this.rotation[0];
    const y = this.rotation[1];
    const z = this.rotation[2];
    const m = this.movement[2];
    return x !== 0 || y !== 0 || z !== 0 || m !== 0;
  }

  applyAdjustments() {
    this.actor?.setUserMatrix(this.matrix);
  }

  applyTextureScalars() {
    const mapper = this.actor.getMapper();
    if (!mapper) return;
    const polydata = mapper.getInputData() as vtkPolyData;
    polydata.getPointData().setScalars(this.textureScalars);
  }

  setRenderingMode(mode: RenderingMode) {
    if (!this.actor) {
      return;
    }

    if (mode === RenderingMode.TrueColor && !this.hasTrueColor()) {
      mode = RenderingMode.Colored;
    } else if (mode === RenderingMode.Scalar && !this.hasScalar()) {
      mode = RenderingMode.Colored;
    }

    let textureOn = false;
    let scalarOn = false;
    let lightOn = true;
    let color = { r: 1, g: 1, b: 1 };
    switch (mode) {
      case RenderingMode.Colored:
        color = this.preferColor;
        break;
      case RenderingMode.TrueColor:
        lightOn = false;
        if (this.hasTexture()) {
          textureOn = true;
        } else {
          this.applyTextureScalars();
          scalarOn = true;
        }
        break;
      case RenderingMode.Scalar:
        scalarOn = true;
        break;
    }

    this.switchTexture(textureOn);
    this.actor.getMapper()?.setScalarVisibility(scalarOn);
    this.switchLight(lightOn);
    this.actor.getProperty().setColor(color.r, color.g, color.b);
  }

  setIsActive(isActive: boolean) {
    this.isActive = isActive;
    this.setVisible(isActive);
  }
}

class NormalMesh extends Mesh {
  marginLine: MarginLine[];
  acquisitionDateTime: string;
  scanId: string;

  constructor(
    name: string,
    mesh: IMeshNode,
    color: number[],
    acquisitionDateTime: string,
    scanId: string
  ) {
    super(name, mesh, scanId, color);
    this.visible = true;
    this.marginLine = [];
    this.acquisitionDateTime = acquisitionDateTime;
    this.scanId = scanId;
  }

  async build() {
    const [actor, texture, textureScalars] = await createMeshActor(this.mesh);
    this.actor = actor!;
    this.texture = texture!;
    this.textureScalars = textureScalars!;
    this.setBackface(this.isBackfaceAvailable());
  }

  duplicate(color: number[] | null) {
    return new NormalMesh(
      this.name,
      this.mesh,
      color
        ? color
        : [this.preferColor.r, this.preferColor.g, this.preferColor.b],
      this.acquisitionDateTime,
      this.scanId
    );
  }
}

const buildMesh = async (
  mesh: IMeshNode,
  color: number[],
  acquisitionDateTime: string,
  scanId: string
) => {
  let finalMesh = new NormalMesh(
    mesh.meshPart,
    mesh,
    color,
    acquisitionDateTime,
    scanId
  );
  finalMesh.mesh = mesh;
  await finalMesh.build();
  finalMesh.setRenderingMode(RenderingMode.Scalar);
  return finalMesh;
};

class OccluMesh extends Mesh {
  constructor(name: string, mesh: IMeshNode, scanId: string) {
    super(name, mesh, scanId);
    this.visible = true;
  }
  build() {}
}

class CompareMesh extends Mesh {
  constructor(name: string, mesh: IMeshNode, scanId: string) {
    super(name, mesh, scanId);
    this.visible = true;
  }
  async build() {
    const [actor, texture] = await createMeshActor(this.mesh);

    this.actor = actor!;
    this.texture = texture!;
    this.setBackface(this.isBackfaceAvailable());
  }
}

const findMeshPairList = (meshList: NormalMesh[]) => {
  const ret: { upper: NormalMesh; lower: NormalMesh }[] = [];

  const uppers = meshList.filter((m) => m.isUpperJaw());
  const lowers = meshList.filter((m) => m.isLowerJaw());
  uppers.forEach((upper) => {
    const lower = lowers.find((tmp) => tmp.scanId === upper.scanId);
    if (undefined !== lower) {
      ret.push({ upper, lower });
    }
  });

  return ret;
};

const createGroupedMeshes = (meshList: NormalMesh[]) => {
  const lst = findMeshPairList(meshList);
  const upperJawMeshes = lst.map((a) => a.upper);
  const lowerJawMeshes = lst.map((a) => a.lower);
  return { upperJawMeshes, lowerJawMeshes };
};

const findFilteredMeshPairList = (meshList: NormalMesh[]) => {
  const ret: { upper: NormalMesh | null; lower: NormalMesh | null }[] = [];

  const uppers = meshList.filter((m) => m.isUpperJaw());
  const lowers = meshList.filter((m) => m.isLowerJaw());

  const matchedLowerScanIds = new Set();

  uppers.forEach((upper) => {
    const lower = lowers.find((tmp) => tmp.scanId === upper.scanId);
    if (lower !== undefined) {
      ret.push({ upper, lower });
      matchedLowerScanIds.add(lower.scanId);
    }
  });

  const unpairedLowers = lowers.filter(
    (lower) => !matchedLowerScanIds.has(lower.scanId)
  );
  unpairedLowers.forEach((lower) => {
    ret.push({ upper: null, lower });
  });

  const matchedUpperScanIds = new Set(ret.map((pair) => pair.upper?.scanId));
  const unpairedUppers = uppers.filter(
    (upper) => !matchedUpperScanIds.has(upper.scanId)
  );
  unpairedUppers.forEach((upper) => {
    ret.push({ upper, lower: null });
  });

  return ret;
};

const createFilteredMeshes = (meshList: NormalMesh[]) => {
  const filteredList = findFilteredMeshPairList(meshList);
  const upperJawMeshes = filteredList.map((pair) => pair.upper);
  const lowerJawMeshes = filteredList.map((pair) => pair.lower);
  return { upperJawMeshes, lowerJawMeshes };
};

const determineArchType = (latestScanId: string, meshList: NormalMesh[]) => {
  const filteredPairs = findFilteredMeshPairList(meshList);
  const latestPair = filteredPairs.find(
    (pair) =>
      (pair.upper && pair.upper.scanId === latestScanId) ||
      (pair.lower && pair.lower.scanId === latestScanId)
  );
  if (latestPair) {
    if (latestPair.upper && latestPair.lower) {
      return "FULL_ARCH";
    } else if (latestPair.upper) {
      return "MAXILLARY";
    } else if (latestPair.lower) {
      return "MANDIBULAR";
    }
  }
  return null;
};

export {
  Mesh,
  NormalMesh,
  buildMesh,
  OccluMesh,
  isLowerJaw,
  isUpperJaw,
  CompareMesh,
  findMeshPairList,
  createGroupedMeshes,
  createFilteredMeshes,
  determineArchType,
};
