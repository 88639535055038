import React from "react";

const MirrorIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0H13V24H11V0ZM15 6.53518C15 6.20083 15.1671 5.8886 15.4453 5.70313L22.4453 1.03647C23.1099 0.593431 24 1.06982 24 1.86852V22.1315C24 22.9302 23.1099 23.4066 22.4453 22.9635L15.4453 18.2969C15.1671 18.1114 15 17.7992 15 17.4648V6.53518ZM7 7.07037V16.9296L2 20.263V3.73703L7 7.07037ZM0 1.86852C0 1.06982 0.890145 0.59343 1.5547 1.03647L8.5547 5.70313C8.8329 5.8886 9 6.20083 9 6.53518V17.4648C9 17.7992 8.8329 18.1114 8.5547 18.2969L1.5547 22.9635C0.890146 23.4066 0 22.9302 0 22.1315V1.86852Z"
        fill="#4D4D4D"
      />
    </svg>
  );
};

export default MirrorIcon;
