import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import {
  CommentsWrapper,
  CommentText,
  SearchBarContainer,
  SearchBarInput,
  StyledLayout,
  HorizontalLine,
  CommentsList,
  CommentsColorIndicator,
  DateSpan,
  CommentScanInfo,
  CommentTimeElapsedSpan,
  CommentHeader,
  ActionButton,
  DeleteCommentDialog,
  PromptContainer,
  DialogTitle,
  PromptButtonsContainer,
  CancelButton,
  DeleteButton,
  ActionButtonsContainer,
  CommentContainer,
  MessageConatner,
  HideCommentsButton,
} from "./CommentPanel.styled";
import { AppGlobalDataContext } from "../../../providers/AppGlobalDataProvider";
import CommentIcon from "../../svg-icons/CommentIcon";
import SearchIcon from "../../svg-icons/SearchIcon";
import { Comment } from "../../../model/Comment";
import CommentForm from "./CommentForm";
import { createSphereActor } from "../../../utils/vtkUtils";
import vtkActor from "@kitware/vtk.js/Rendering/Core/Actor";
import vtkGenericRenderWindow from "@kitware/vtk.js/Rendering/Misc/GenericRenderWindow";
import vtkPointPicker from "@kitware/vtk.js/Rendering/Core/PointPicker";
import { vtkSubscription } from "@kitware/vtk.js/interfaces";
import { Vector3 } from "@kitware/vtk.js/types";
import vtkTransform from "@kitware/vtk.js/Common/Transform/Transform";
import { NormalMesh } from "../../../model/Mesh";
import {
  timeElapsedBetweenDates,
  formatDateFromString,
} from "../../../utils/dateTime";
import CommentBox from "./CommentBox";
import { useClickOutsideOfComponent } from "../../../utils/useClickOutsideOfComponent";
import TrashIcon from "../../svg-icons/TrashIcon";
import WarningIcon from "../../svg-icons/WarningIcon";
import PencilIcon from "../../svg-icons/PencilIcon";

export interface ICommentToolProps {
  genericRenderWindows: (vtkGenericRenderWindow | undefined)[];
}

export const CommentPanel: React.FC<ICommentToolProps> = ({
  genericRenderWindows,
}: ICommentToolProps) => {
  const {
    isCommentModeActive,
    setIsCommentModeActive,
    isHideCommentsOn,
    setIsHideCommentsOn,
    comments,
    setComments,
    normalMeshes,
    activeNormalMeshes,
    groupedMeshesByDicomId,
    translations,
  } = useContext(AppGlobalDataContext);
  const [searchText, setSearchText] = useState("");
  const [showCommentForm, setShowCommentForm] = useState(false);
  const [commentOnFocus, setCommentOnFocus] = useState<Comment | null>(null);
  const [isInEditMode, setIsInEditMode] = useState<boolean>(false);
  const [showDeleteCommentPrompt, setShowDeleteCommentPrompt] = useState(false);
  const [clickedOnComment, setClickedOnComment] = useState(false);
  const [commentFormPosition, setCommentFormPosition] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });
  const [comment3DPosition, setComment3DPosition] = useState<{
    x: number;
    y: number;
    z: number;
  }>({ x: 0, y: 0, z: 0 });
  const [commentPickedNormalMesh, setCommentPickedNormalMesh] =
    useState<NormalMesh>();
  const commentsRef = useRef<Comment[]>([]);
  const commentActorRef = useRef<vtkActor | null>(null);
  const pickingListenersRef = useRef<vtkSubscription[]>([]);
  const leftBtnPressPosition = useRef<{ x: number; y: number } | null>(null);

  // Remove focused comments when clicking elsewhere
  const commentsListRef = useRef<HTMLUListElement>(null);
  const rendererListenersRef = useRef<vtkSubscription[]>([]);
  useClickOutsideOfComponent(
    commentsListRef,
    (event) => {
      const target = event.target as HTMLElement;
      if (target.id !== "snapshotBtn") {
        !clickedOnComment && setCommentOnFocus(null);
        setClickedOnComment(false);
      }
    },
    showDeleteCommentPrompt
  );

  const latestActiveScanId = useMemo(
    () =>
      activeNormalMeshes.filter((nm) => !nm.scanId.startsWith("uploadId-"))[0]
        ?.scanId,
    [activeNormalMeshes]
  );
  const commentsOfLatestActiveScan = useMemo(
    () =>
      latestActiveScanId
        ? comments.filter((c) => c.parentMesh.scanId === latestActiveScanId)
        : [],
    [latestActiveScanId, comments]
  );

  const filteredGroupedComments = useMemo(() => {
    const groupesComments: { [key: string]: Comment[] } = {};
    for (let dicomId of Object.keys(groupedMeshesByDicomId || {})) {
      const relatedComments = comments.filter(
        (c) =>
          c.text.toLowerCase().includes(searchText.toLowerCase()) &&
          c.parentMesh.scanId === dicomId
      );
      if (relatedComments.length) {
        groupesComments[dicomId] = relatedComments;
      }
    }
    return groupesComments;
  }, [comments, groupedMeshesByDicomId, searchText]);

  const removeCommentActor = useCallback(() => {
    genericRenderWindows.forEach((genericRenderWindow) => {
      if (!genericRenderWindow || !commentActorRef.current) return;
      genericRenderWindow.getInteractor().enable();
      genericRenderWindow.getRenderer().removeActor(commentActorRef.current);
      genericRenderWindow.getRenderWindow().render();
    });
    commentActorRef.current = null;
  }, [commentActorRef, genericRenderWindows]);

  useEffect(() => {
    // If current comment form is hidden, remove current comment actor
    if (!showCommentForm) {
      removeCommentActor();
    }
  }, [showCommentForm, removeCommentActor]);

  // Handle picking
  const handlePicking = useCallback(
    (e: any) => {
      if (!isCommentModeActive) {
        for (let genericRenderWindow of genericRenderWindows) {
          if (!genericRenderWindow) continue;
          const renderer = genericRenderWindow.getRenderer();

          if (renderer === e.pokedRenderer) {
            const picker = genericRenderWindow.getInteractor().getPicker();
            if (!picker) return;
            picker.pick([e.position.x, e.position.y, 0.0], e.pokedRenderer);

            if (picker.getActors().length) {
              const pickedComment = picker
                .getActors()
                .map((a: vtkActor) => comments.find((c) => c.actor === a))
                .filter((l: any) => !!l)[0];

              if (pickedComment) {
                setCommentOnFocus(pickedComment);
              } else {
                setCommentOnFocus(null);
              }
            }
          }
        }
        return;
      }

      //when comment mode is active
      for (let genericRenderWindow of genericRenderWindows) {
        if (!genericRenderWindow) continue;
        const renderer = genericRenderWindow.getRenderer();
        if (renderer === e.pokedRenderer) {
          const picker = genericRenderWindow.getInteractor().getPicker();
          if (!picker) return;

          const screenPosition = e.position;

          // Trigger picking only when mouse is not moving
          if (leftBtnPressPosition?.current) {
            const mouseDistance = Math.sqrt(
              Math.pow(screenPosition.x - leftBtnPressPosition.current.x, 2) +
                Math.pow(screenPosition.y - leftBtnPressPosition.current.y, 2)
            );
            if (mouseDistance > 10) {
              return;
            }
          }

          picker.pick(
            [screenPosition.x, screenPosition.y, 0.0],
            e.pokedRenderer
          );

          const pickedComment = picker
            .getActors()
            .map((a: vtkActor) => comments.find((c) => c.actor === a))
            .filter((l: any) => !!l)[0];

          if (pickedComment) {
            setCommentOnFocus(pickedComment);
            setClickedOnComment(true);
            return;
          }

          if (
            picker.getActors().length > 0 &&
            picker.getPickedPositions().length > 0
          ) {
            const picked3DPosition = picker.getPickedPositions()[0];

            // Add sphere actor to the 3D scene
            commentActorRef.current = createSphereActor(
              picked3DPosition[0],
              picked3DPosition[1],
              picked3DPosition[2]
            );
            genericRenderWindow.getRenderer().addActor(commentActorRef.current);
            genericRenderWindow.getRenderWindow().render();

            // Convert picked position to original 3D position relative to mesh
            const pickedNormalMesh: NormalMesh = picker
              .getActors()
              .map((a: vtkActor) => normalMeshes.find((nm) => nm.actor === a))
              .filter(
                (nm: NormalMesh) => nm && !nm.scanId.startsWith("uploadId-")
              )
              .sort(
                (a: NormalMesh, b: NormalMesh) =>
                  Number(b.acquisitionDateTime) - Number(a.acquisitionDateTime)
              )[0];
            const pickedActor = pickedNormalMesh?.actor;

            if (!pickedActor) {
              removeCommentActor();
              return;
            }

            let original3DPosition: Vector3 = [
              picked3DPosition[0],
              picked3DPosition[1],
              picked3DPosition[2],
            ];
            const pickedActorMatrix = pickedActor.getUserMatrix();
            if (pickedActorMatrix) {
              const transform = vtkTransform.newInstance();
              transform.setMatrix(pickedActorMatrix);
              transform
                .getInverse()
                .transformPoint(picked3DPosition, original3DPosition);
            }

            const clientWidth = genericRenderWindow.getContainer().clientWidth;
            const viewWidth = genericRenderWindow
              .getRenderWindow()
              .getViews()[0]
              .getSize()[0];
            const screenScale = viewWidth / clientWidth;

            // Update comment position adjusted based on the viewport offset and scale, and show form
            setCommentFormPosition({
              x:
                screenPosition.x / screenScale +
                genericRenderWindow.getContainer().offsetLeft,
              y: screenPosition.y / screenScale,
            });

            setComment3DPosition({
              x: original3DPosition[0],
              y: original3DPosition[1],
              z: original3DPosition[2],
            });
            setCommentPickedNormalMesh(pickedNormalMesh);
            setShowCommentForm(true);
          }
        }
      }
    },
    [
      isCommentModeActive,
      genericRenderWindows,
      commentActorRef,
      normalMeshes,
      removeCommentActor,
      comments,
    ]
  );

  // Enable picking
  useEffect(() => {
    pickingListenersRef.current.forEach((listener) => {
      listener.unsubscribe();
    });
    pickingListenersRef.current = [];

    genericRenderWindows.forEach((genericRenderWindow) => {
      if (!genericRenderWindow) return;
      const interactor = genericRenderWindow.getInteractor();
      const renderer = genericRenderWindow.getRenderer();

      const onLeftButtonPress = (e: any) => {
        if (e.pokedRenderer === renderer) {
          leftBtnPressPosition.current = {
            x: e.position.x,
            y: e.position.y,
          };
        }
      };
      const onLeftButtonRelease = (e: any) => {
        if (e.pokedRenderer === renderer) {
          handlePicking(e);
        }
      };
      const pressListener = interactor.onLeftButtonPress(onLeftButtonPress);
      const releaseListener =
        interactor.onLeftButtonRelease(onLeftButtonRelease);

      pickingListenersRef.current.push(pressListener, releaseListener);
    });
  }, [genericRenderWindows, handlePicking]);

  useEffect(() => {
    genericRenderWindows.forEach((genericRenderWindow) => {
      if (!genericRenderWindow) return;
      if (!isCommentModeActive) {
        genericRenderWindow.getInteractor().enable();
        setShowCommentForm(false);
      } else {
        if (showCommentForm) {
          genericRenderWindow.getInteractor().disable();
        } else {
          genericRenderWindow.getInteractor().enable();
        }
      }
    });
  }, [
    isCommentModeActive,
    showCommentForm,
    commentOnFocus,
    genericRenderWindows,
  ]);

  // Update comments
  useEffect(() => {
    const isSingleRenderWindow = genericRenderWindows.length === 1;
    genericRenderWindows.forEach((genericRenderWindow) => {
      if (!genericRenderWindow) return;
      const renderer = genericRenderWindow.getRenderer();

      // Remove existing comments actors
      commentsRef.current.forEach((comment) => {
        renderer.removeActor(comment.actor);
      });

      if (!isHideCommentsOn) {
        const actors = renderer.getActors();
        comments.forEach((comment) => {
          let opacity = 1.0;
          let addComment = false;
          if (isSingleRenderWindow) {
            // In single render window mode, if comment mode is enabled, we display all comments, but 0.5 opacity to comments attached to old scans
            // Otherwise, if comment mode is disabled, we display only comments attached to latest scan
            const isCommentOfLatestActiveScan =
              commentsOfLatestActiveScan.includes(comment);
            if (isCommentModeActive) {
              addComment = true;
              if (!isCommentOfLatestActiveScan) opacity = 0.5;
            } else {
              if (isCommentOfLatestActiveScan) {
                addComment = true;
              }
            }
          } else {
            // In multi render windows, we display only comments of present actors
            actors.forEach((meshActor) => {
              if (comment.parentMesh.actor === meshActor) {
                addComment = true;
                return;
              }
            });
          }
          if (addComment) {
            comment.actor.getProperty().setOpacity(opacity);
            renderer.addActor(comment.actor);
            commentsRef.current.push(comment);
          }
        });
      }
      genericRenderWindow.getRenderWindow().render();
    });
  }, [
    comments,
    isHideCommentsOn,
    commentsOfLatestActiveScan,
    genericRenderWindows,
    isCommentModeActive,
  ]);

  // Init picker
  useEffect(() => {
    genericRenderWindows.forEach((genericRenderWindow) => {
      if (
        genericRenderWindow &&
        !genericRenderWindow.getInteractor().getPicker()
      ) {
        const newPicker = vtkPointPicker.newInstance();
        newPicker.setPickFromList(1);
        newPicker.setTolerance(0.005);
        genericRenderWindow.getInteractor().setPicker(newPicker);
      }
    });
  }, [genericRenderWindows]);

  // Remove comment on focus upon mesh movement
  useEffect(() => {
    genericRenderWindows.forEach((genericRenderWindow) => {
      if (!genericRenderWindow) return;

      // Cleanup listeners
      rendererListenersRef.current.forEach((listener) => {
        listener.unsubscribe();
      });
      rendererListenersRef.current = [];

      const renderer = genericRenderWindow.getRenderer();
      const listener = renderer.onEvent(() => {
        setCommentOnFocus(null);
        setIsInEditMode(false);
      });
      rendererListenersRef.current.push(listener);
    });
  }, [isCommentModeActive, genericRenderWindows]);

  // Show comments after hiding them and opening Comments pannel again
  useEffect(() => {
    isCommentModeActive && setIsHideCommentsOn(false);
  }, [isCommentModeActive, setIsHideCommentsOn]);

  const handleCommentFormCancel = () => {
    setShowCommentForm(false);
    removeCommentActor();
  };

  const handleCommentFormSave = (commentText: string) => {
    if (!commentPickedNormalMesh) return;

    setComments((prevComments) => [
      ...prevComments,
      new Comment(commentText, comment3DPosition, commentPickedNormalMesh),
    ]);
    removeCommentActor();
    setShowCommentForm(false);
  };

  const handleCommentFocus = (comment: Comment) => {
    if (showCommentForm) return;

    comment === commentOnFocus
      ? setCommentOnFocus(null)
      : setCommentOnFocus(comment);
    setIsInEditMode(false);
  };

  const handleDeleteComment = () => {
    setComments(comments.filter((c) => c !== commentOnFocus));
    setShowDeleteCommentPrompt(false);
    setCommentOnFocus(null);
  };

  const handleHideComments = () => {
    setIsHideCommentsOn(true);
    setIsCommentModeActive(false);
  };

  return (
    <>
      {isCommentModeActive && (
        <StyledLayout>
          <CommentsWrapper>
            <SearchBarContainer style={{ display: "none" }}>
              <SearchIcon />
              <SearchBarInput
                type="text"
                placeholder={translations["SEARCH"]}
                value={searchText}
                onChange={(e: {
                  target: { value: React.SetStateAction<string> };
                }) => setSearchText(e.target.value)}
              />
            </SearchBarContainer>
            <HorizontalLine style={{ display: "none" }} />

            {Object.entries(filteredGroupedComments).length === 0 ? (
              <MessageConatner>
                <CommentIcon />
                <CommentText>
                  {
                    translations[
                      "CLICK_ANYWHERE_ON_MESH_TO_LEAVE_COMMENT"
                    ].split(". ")[0]
                  }
                  . <br />
                  {
                    translations[
                      "CLICK_ANYWHERE_ON_MESH_TO_LEAVE_COMMENT"
                    ].split(". ")[1]
                  }
                </CommentText>
              </MessageConatner>
            ) : (
              <CommentsList ref={commentsListRef}>
                {Object.entries(filteredGroupedComments).map(
                  ([dicomId, comments]) => (
                    <li key={dicomId}>
                      <CommentScanInfo>
                        <CommentsColorIndicator
                          $readOnly
                          $color={comments[0].parentMesh.preferColorHex}
                          $isActive={true}
                        />
                        <DateSpan>
                          {formatDateFromString(
                            comments[0].parentMesh.acquisitionDateTime
                          )}
                        </DateSpan>
                        <HorizontalLine />
                      </CommentScanInfo>
                      {comments
                        .sort(
                          (a, b) =>
                            Number(b.creationDate) - Number(a.creationDate)
                        )
                        .map((comment, index) => (
                          <CommentContainer
                            key={dicomId + index}
                            onClick={() => handleCommentFocus(comment)}
                            $focus={comment === commentOnFocus}
                          >
                            <CommentHeader>
                              <CommentIcon />
                              <CommentTimeElapsedSpan>
                                {timeElapsedBetweenDates(
                                  comment.creationDate,
                                  new Date()
                                )}
                              </CommentTimeElapsedSpan>
                              <ActionButtonsContainer>
                                <ActionButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (!showCommentForm) {
                                      setCommentOnFocus(comment);
                                      setIsInEditMode(true);
                                    }
                                  }}
                                >
                                  <PencilIcon />
                                </ActionButton>
                                <ActionButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (!showCommentForm) {
                                      setCommentOnFocus(comment);
                                      setShowDeleteCommentPrompt(true);
                                    }
                                  }}
                                >
                                  <TrashIcon />
                                </ActionButton>
                              </ActionButtonsContainer>
                            </CommentHeader>
                            <CommentText>{comment.text}</CommentText>
                          </CommentContainer>
                        ))}
                    </li>
                  )
                )}
              </CommentsList>
            )}

            <HideCommentsButton onClick={handleHideComments}>
              {translations["HIDE_COMMENTS"]}
            </HideCommentsButton>
          </CommentsWrapper>
        </StyledLayout>
      )}
      {showCommentForm && (
        <CommentForm
          position2D={commentFormPosition}
          onCancel={handleCommentFormCancel}
          onSave={handleCommentFormSave}
        />
      )}
      {commentOnFocus && (
        <CommentBox
          genericRenderWindows={genericRenderWindows}
          comment={commentOnFocus}
          setCommentOnFocus={setCommentOnFocus}
          showDeleteCommentPrompt={showDeleteCommentPrompt}
          setShowDeleteCommentPrompt={setShowDeleteCommentPrompt}
          isInEditMode={isInEditMode}
          setIsInEditMode={setIsInEditMode}
        />
      )}
      <DeleteCommentDialog open={showDeleteCommentPrompt}>
        <PromptContainer>
          <WarningIcon />
          <DialogTitle>{translations["DELETE_COMMENT"]}</DialogTitle>
          <p>{translations["YOU_ARE_ABOUT_TO_DELETE_THE_SELECTED_COMMENT"]}</p>
          <PromptButtonsContainer>
            <CancelButton
              onClick={(e) => {
                e.stopPropagation();
                setShowDeleteCommentPrompt(false);
              }}
            >
              {translations["CANCEL"]}
            </CancelButton>
            <DeleteButton onClick={handleDeleteComment}>
              {translations["DELETE"]}
            </DeleteButton>
          </PromptButtonsContainer>
        </PromptContainer>
      </DeleteCommentDialog>
    </>
  );
};

export default CommentPanel;
