import React, { useState, useMemo, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Backdrop,
  Button,
  Buttons,
  Content,
  Dialog,
  Title,
  InfoImage,
  Instructions,
  CancelButton,
  ScanLabelContainer,
  CustomCheckbox,
  ScanLabel,
} from "./DialogBox.styled";
import infoImage from "../../../images/info.png";
import { formatDateFromString } from "../../../utils/dateTime";
import { determineArchType } from "../../../model/Mesh";
import { AppGlobalDataContext } from "../../../providers/AppGlobalDataProvider";

interface NormalMesh {
  acquisitionDateTime: string;
  id: string;
  scanId: string;
  preferColorHex: string;
  name: string;
}

export interface GroupedMeshes {
  upperJawMeshes: NormalMesh[];
  lowerJawMeshes: NormalMesh[];
}

interface DialogBoxProps {
  meshes?: NormalMesh[];
  onClose: () => void;
  onConfirm: (selectedScans: string[]) => void;
  groupedMeshes?: GroupedMeshes;
  cancelAsHide?: boolean;
}
const getTitle = (path: string, translations: { [key: string]: string }) => {
  switch (path) {
    case "/distance-mapping":
      return translations["DISTANCE_MAPPING"];
    case "/occlusion-proximity":
      return translations["OCCLUSION_EVOLUTION"];
    case "/measurement":
      return translations["MEASUREMENT"];
    case "/point-matching":
      return translations["POINT_BASED_MATCHING"];
    default:
      return "Dialog";
  }
};

const getInstructions = (
  path: string,
  translations: { [key: string]: string }
) => {
  switch (path) {
    case "/distance-mapping":
      return translations["PLEASE_SELECT_2SCANS"];
    case "/point-matching":
      return translations["PLEASE_SELECT_1SCAN_TO_ALIGN_WITH_LATEST_SCAN"];
    case "/measurement":
      return translations["PLEASE_SELECT_2SCANS"];
    case "/occlusion-proximity":
      return "Please select a scan:";
    default:
      return translations["PLEASE_SELECT_2SCANS"];
  }
};

const getUniqueScans = (meshList: NormalMesh[]) => {
  const scans = meshList?.reduce(
    (
      acc: { [key: string]: { acquisitionDateTime: string; color: string } },
      mesh
    ) => {
      if (!acc[mesh.scanId]) {
        acc[mesh.scanId] = {
          acquisitionDateTime: mesh.acquisitionDateTime,
          color: mesh.preferColorHex,
        };
      }
      return acc;
    },
    {}
  );
  return scans;
};

const DialogBox: React.FC<DialogBoxProps> = ({
  onClose,
  onConfirm,
  groupedMeshes,
  cancelAsHide = false,
}) => {
  const [selectedScans, setSelectedScans] = useState<string[]>([]);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { translations, normalMeshes } = useContext(AppGlobalDataContext);

  const title = useMemo(
    () => getTitle(pathname, translations),
    [pathname, translations]
  );
  const instructions = useMemo(
    () => getInstructions(pathname, translations),
    [pathname, translations]
  );

  const uniqueScans = useMemo(() => {
    // Sort scans so the latest non-imported scans appear at the top
    const sortedScans = [...normalMeshes].sort((a, b) => {
      const isAImported = a.scanId.startsWith("uploadId-");
      const isBImported = b.scanId.startsWith("uploadId-");

      if (isAImported && !isBImported) return 1;
      if (!isAImported && isBImported) return -1;

      return 0;
    });

    return getUniqueScans(sortedScans);
  }, [normalMeshes]);

  const isRadio = useMemo(
    () => pathname === "/occlusion-proximity",
    [pathname]
  );
  const isLatestAlwaysChosen = useMemo(
    () => pathname === "/point-matching",
    [pathname]
  );

  useEffect(() => {
    if (isLatestAlwaysChosen) {
      const topScanId = Object.keys(uniqueScans)[0];
      if (topScanId) {
        setSelectedScans([topScanId]);
      }
    }
  }, [isLatestAlwaysChosen, uniqueScans]);

  const archType = useMemo(() => {
    if (selectedScans.length > 0) {
      return determineArchType(selectedScans[0], normalMeshes);
    }
    return null;
  }, [selectedScans, normalMeshes]);

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const scanId = event.target.value;
    if (selectedScans.includes(scanId)) {
      setSelectedScans(selectedScans.filter((id) => id !== scanId));
    } else if (isRadio) {
      setSelectedScans([scanId]);
    } else if (selectedScans.length < 2) {
      setSelectedScans([...selectedScans, scanId]);
    }
  };

  const handleConfirm = () => {
    if (selectedScans.length === 2 || (isRadio && selectedScans.length === 1)) {
      onConfirm(selectedScans);
      onClose();
    }
  };

  const handleCancel = () => {
    onClose();
    if (!isRadio && !cancelAsHide) navigate("/");
  };

  if (Object.keys(uniqueScans).length <= 2) {
    return null;
  }

  return (
    <Backdrop>
      <Dialog>
        <InfoImage src={infoImage} alt="Info" />
        <Title>{title}</Title>
        <Instructions>{instructions}</Instructions>
        <Content>
          {Object.entries(uniqueScans).map(
            ([scanId, { acquisitionDateTime, color }], index) => (
              <ScanLabelContainer key={scanId}>
                <CustomCheckbox
                  id={scanId}
                  name="scan"
                  value={scanId}
                  onChange={handleSelect}
                  checked={selectedScans.includes(scanId)}
                  disabled={
                    (isLatestAlwaysChosen && scanId === selectedScans[0]) ||
                    (selectedScans.length === 2 &&
                      !selectedScans.includes(scanId)) ||
                    (archType === "MAXILLARY" &&
                      !groupedMeshes?.upperJawMeshes?.some(
                        (mesh) => mesh.scanId === scanId
                      )) ||
                    (archType === "MANDIBULAR" &&
                      !groupedMeshes?.lowerJawMeshes?.some(
                        (mesh) => mesh.scanId === scanId
                      ))
                  }
                  color={color}
                />
                <ScanLabel
                  htmlFor={scanId}
                  disabled={
                    selectedScans.length === 2 &&
                    !selectedScans.includes(scanId)
                  }
                  selected={selectedScans.includes(scanId)}
                >
                  {formatDateFromString(acquisitionDateTime)}
                </ScanLabel>
              </ScanLabelContainer>
            )
          )}
        </Content>
        <Buttons>
          <CancelButton onClick={handleCancel}>
            {" "}
            {translations["CANCEL"]}
          </CancelButton>
          <Button
            onClick={handleConfirm}
            disabled={
              (!isRadio && selectedScans.length !== 2) ||
              (isRadio && selectedScans.length < 1)
            }
          >
            {translations["OK"]}
          </Button>
        </Buttons>
      </Dialog>
    </Backdrop>
  );
};

export default DialogBox;
