import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import html2canvas from "html2canvas";
import vtkGenericRenderWindow from "@kitware/vtk.js/Rendering/Misc/GenericRenderWindow";
import vtkRenderer from "@kitware/vtk.js/Rendering/Core/Renderer";
import {
  ButtonsContainer,
  DropdownContainer,
  SnapshotAnimatedImage,
} from "./GenericToolBar.styled";
import commentsImage from "../../../images/comments.png";
import trueColorImage from "../../../images/true-color.png";
import snapshotImage from "../../../images/snapshot.png";
import zoomFitImage from "../../../images/zoom_fit.png";
import viewOrientationImage from "../../../images/ViewOrientation.png";
import frontViewImage from "../../../images/front-view.png";
import backViewImage from "../../../images/back-view.png";
import leftViewImage from "../../../images/left-view.png";
import rightViewImage from "../../../images/right-view.png";
import topViewImage from "../../../images/top-view.png";
import bottomViewImage from "../../../images/bottom-view.png";
import expandImage from "../../../images/expand.png";
import collapseImage from "../../../images/collapse.png";
import { ViewOrientation } from "../../../utils/common";
import { AppGlobalDataContext } from "../../../providers/AppGlobalDataProvider";
import { MeasurementContext } from "../../layouts/Measurement";
import Tooltip from "../Tooltip/Tooltip";
import { RenderingMode } from "../../../model/Mesh";
import TrackedButton from "../TrackedButton/TrackedButton";
export interface IGenericToolBarProps {
  cameraOrientation?: ViewOrientation;
  setCameraOrientation?: React.Dispatch<React.SetStateAction<ViewOrientation>>;
  genericRenderWindow?: vtkGenericRenderWindow | null;
  multipleRenderers?: vtkRenderer[];
  multipleGenericRenderWindows?: vtkGenericRenderWindow[];
  withTrueColor?: boolean;
  withSnapshot?: boolean;
  withViewOrientation?: boolean;
  withComments?: boolean;
}

export const GenericToolBar: React.FC<IGenericToolBarProps> = ({
  cameraOrientation = ViewOrientation.Front,
  setCameraOrientation,
  genericRenderWindow,
  multipleRenderers,
  multipleGenericRenderWindows,
  withTrueColor = true,
  withSnapshot = true,
  withViewOrientation = true,
  withComments = true,
}: IGenericToolBarProps) => {
  const {
    normalMeshes,
    translations,
    setCapturedSnapshots,
    isTrueColorOn,
    setIsTrueColorOn,
    isCommentModeActive,
    setIsCommentModeActive,
    capturedSnapshots,
  } = useContext(AppGlobalDataContext);
  const { isCreatingMeasurement } = useContext(MeasurementContext);
  const { pathname } = useLocation();
  const [isExpanded, setIsExpanded] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isSnapshotAnimationOn, setIsSnapshotAnimationOn] = useState(false);

  const resetViewButtonUponRotation = useCallback(() => {
    if (!genericRenderWindow || !setCameraOrientation) return;

    let lastRendererEvents: string[] = [];
    const renderer = genericRenderWindow.getRenderer();
    renderer.onEvent((e: any) => {
      lastRendererEvents.length < 7
        ? lastRendererEvents.push(e.type)
        : (lastRendererEvents = [e.type]);

      if (
        lastRendererEvents.length === 7 &&
        !lastRendererEvents.includes("ResetCameraEvent")
      ) {
        setCameraOrientation(0);
      }
    });
  }, [genericRenderWindow, setCameraOrientation]);

  useEffect(() => {
    if (genericRenderWindow) {
      resetViewButtonUponRotation();
    }
  }, [genericRenderWindow, resetViewButtonUponRotation]);

  const handleViewOrientationClick = () => {
    setShowDropdown(!showDropdown);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    setShowDropdown(false);
  };

  const handleZoomBtnClick = useCallback(() => {
    if (genericRenderWindow) {
      const renderer = genericRenderWindow.getRenderer();
      renderer.resetCamera();
      genericRenderWindow.resize();
    } else if (multipleRenderers && multipleGenericRenderWindows) {
      multipleRenderers.forEach((r) => r.resetCamera());
      multipleGenericRenderWindows.forEach((w) => w.resize());
    }
  }, [genericRenderWindow, multipleRenderers, multipleGenericRenderWindows]);

  const handleTrueColorClick = () => {
    if (!isTrueColorOn) {
      normalMeshes.forEach((mesh) => {
        mesh.setRenderingMode(RenderingMode.TrueColor);
      });
      genericRenderWindow?.getRenderWindow().render();
      setIsTrueColorOn(true);
    } else {
      normalMeshes.forEach((mesh) => {
        mesh.setRenderingMode(RenderingMode.Colored);
      });
      genericRenderWindow?.getRenderWindow().render();
      setIsTrueColorOn(false);
    }
  };

  const handleSnapshotClick = async () => {
    if (genericRenderWindow) {
      const isInteractorEnabled = genericRenderWindow
        .getInteractor()
        .getEnabled();

      // If interactor has been disabled - enable it before creating the snapshot and after that return it to disabled. This is needed in order to be captured on the canvas
      if (isInteractorEnabled) {
        genericRenderWindow.getRenderWindow().render();
        createSnapshot();
      } else {
        genericRenderWindow.getInteractor().enable();
        genericRenderWindow.getRenderWindow().render();
        await createSnapshot();
        genericRenderWindow?.getInteractor().disable();
      }
    }

    if (multipleGenericRenderWindows) {
      let measurementRightView = "";

      if (pathname === "/measurement" && isCreatingMeasurement) {
        const rightView = multipleGenericRenderWindows[1]
          .getRenderWindow()
          .getInteractor()
          .getView();

        const image = await rightView.captureNextImage();
        measurementRightView = image;
      }

      const areInteractorsEnabled: boolean[] = multipleGenericRenderWindows.map(
        (el) => el.getInteractor().getEnabled()
      );

      // If interactor has been disabled - enable it before creating the snapshot and after that return it to disabled. This is needed in order to be captured on the canvas
      if (areInteractorsEnabled.every((el) => el)) {
        multipleGenericRenderWindows.forEach((el) => {
          el.getRenderWindow().render();
        });
        createSnapshot(measurementRightView);
      } else {
        multipleGenericRenderWindows.forEach((el, index) => {
          if (!areInteractorsEnabled[index]) {
            el.getInteractor().enable();
          }
          el.getRenderWindow().render();
        });
        await createSnapshot(measurementRightView);
        multipleGenericRenderWindows.forEach((el, index) => {
          if (!areInteractorsEnabled[index]) {
            el.getInteractor().disable();
          }
        });
      }
    }
  };

  const createSnapshot = useCallback(
    async (measurementRightView?: string) => {
      const body = document.body;

      // Hide the snapshot tooltip from the screenshot
      const tooltip = body.querySelector("#snapshotTooltip") as HTMLElement;
      tooltip.style.display = "none";

      // If the user is in the process of creating a measurement, set the right view as a background-image to a div, in order to capture it
      if (pathname === "/measurement" && measurementRightView) {
        const rightMeasurementContainer = body.querySelector(
          "#rightMeasurementContainer"
        ) as HTMLElement;
        rightMeasurementContainer.style.backgroundImage = `url("${measurementRightView}")`;
      }

      const canvas = await html2canvas(body);
      const image = canvas.toDataURL("image/png", 1.0);
      setCapturedSnapshots((prevState) => [
        ...prevState,
        { snapshot: image, saved: false },
      ]);
      setIsSnapshotAnimationOn(true);
    },
    [pathname, setCapturedSnapshots]
  );

  return (
    <ButtonsContainer
      className={!isExpanded ? "collapsed btns" : "btns"}
      data-html2canvas-ignore
    >
      {withComments && (
        <Tooltip title={translations["COMMENT"]}>
          <TrackedButton
            className={isCommentModeActive ? "active" : ""}
            onClick={() => setIsCommentModeActive(!isCommentModeActive)}
            eventName="Comment button clicked"
          >
            <img src={commentsImage} alt="Comments" />
          </TrackedButton>
        </Tooltip>
      )}

      {withTrueColor && (
        <Tooltip title={translations["TRUE_COLOR"]}>
          <TrackedButton
            className={isTrueColorOn ? "active" : ""}
            onClick={handleTrueColorClick}
            eventName="True Color button clicked"
          >
            <img src={trueColorImage} alt="True Color" />
          </TrackedButton>
        </Tooltip>
      )}

      <Tooltip title={translations["ZOOM_FIT"]}>
        <TrackedButton
          onClick={handleZoomBtnClick}
          eventName="Zoom Fit button clicked"
        >
          <img src={zoomFitImage} alt="Zoom Fit" />
        </TrackedButton>
      </Tooltip>

      {withSnapshot && (
        <Tooltip title={translations["TAKE_SNAPSHOT"]} id="snapshotTooltip">
          <TrackedButton
            onClick={handleSnapshotClick}
            eventName="Snapshot button clicked"
            id="snapshotBtn"
          >
            <img src={snapshotImage} alt="Snapshot" />
          </TrackedButton>
        </Tooltip>
      )}

      {withViewOrientation && (
        <Tooltip title={translations["STANDARD_VIEW"]}>
          <TrackedButton
            className={`viewOrientation ${showDropdown ? "active" : ""}`}
            onClick={handleViewOrientationClick}
            eventName="Standard View button clicked"
          >
            <img src={viewOrientationImage} alt="View Orientation" />
          </TrackedButton>
        </Tooltip>
      )}

      <DropdownContainer
        className={!showDropdown || !isExpanded ? "hidden" : ""}
      >
        <Tooltip title={translations["ANTERIOR_VIEW"]}>
          <TrackedButton
            onClick={() => {
              setCameraOrientation &&
                setCameraOrientation(ViewOrientation.Front);
            }}
            className={
              cameraOrientation === ViewOrientation.Front ? "activeView" : ""
            }
            eventName="Anterior View button clicked"
          >
            <img src={frontViewImage} alt="Front View" />
          </TrackedButton>
        </Tooltip>

        <Tooltip title={translations["POSTERIOR_VIEW"]}>
          <TrackedButton
            onClick={() => {
              setCameraOrientation &&
                setCameraOrientation(ViewOrientation.Back);
            }}
            className={
              cameraOrientation === ViewOrientation.Back ? "activeView" : ""
            }
            eventName="Posterior View button clicked"
          >
            <img src={backViewImage} alt="Back View" />
          </TrackedButton>
        </Tooltip>

        <Tooltip title={translations["LEFT_VIEW"]}>
          <TrackedButton
            onClick={() => {
              setCameraOrientation &&
                setCameraOrientation(ViewOrientation.Left);
            }}
            className={
              cameraOrientation === ViewOrientation.Left ? "activeView" : ""
            }
            eventName="Left View button clicked"
          >
            <img src={leftViewImage} alt="Left View" />
          </TrackedButton>
        </Tooltip>

        <Tooltip title={translations["RIGHT_VIEW"]}>
          <TrackedButton
            onClick={() => {
              setCameraOrientation &&
                setCameraOrientation(ViewOrientation.Right);
            }}
            className={
              cameraOrientation === ViewOrientation.Right ? "activeView" : ""
            }
            eventName="Right View button clicked"
          >
            <img src={rightViewImage} alt="Right View" />
          </TrackedButton>
        </Tooltip>

        <Tooltip title={translations["HEAD_VIEW"]}>
          <TrackedButton
            onClick={() => {
              setCameraOrientation && setCameraOrientation(ViewOrientation.Top);
            }}
            className={
              cameraOrientation === ViewOrientation.Top ? "activeView" : ""
            }
            eventName="Head View button clicked"
          >
            <img src={topViewImage} alt="Top View" />
          </TrackedButton>
        </Tooltip>

        <Tooltip title={translations["FOOT_VIEW"]}>
          <TrackedButton
            onClick={() => {
              setCameraOrientation &&
                setCameraOrientation(ViewOrientation.Bottom);
            }}
            className={
              cameraOrientation === ViewOrientation.Bottom ? "activeView" : ""
            }
            eventName="Foot View button clicked"
          >
            <img src={bottomViewImage} alt="Bottom View" />
          </TrackedButton>
        </Tooltip>
      </DropdownContainer>

      <TrackedButton
        onClick={toggleExpand}
        className="toggleMenuVisibility"
        eventName="Menu Visibility button clicked"
      >
        <img
          src={isExpanded ? collapseImage : expandImage}
          alt={isExpanded ? "Collapse" : "Expand"}
        />
      </TrackedButton>

      {isSnapshotAnimationOn && (
        <SnapshotAnimatedImage
          src={capturedSnapshots[capturedSnapshots.length - 1].snapshot}
          alt="Snapshot preview"
          onAnimationEnd={() => setIsSnapshotAnimationOn(false)}
          $animate={isSnapshotAnimationOn}
        />
      )}
    </ButtonsContainer>
  );
};

export default GenericToolBar;
