import React, {
  useContext,
  useEffect,
  useCallback,
  useRef,
  useState,
  useMemo,
} from "react";
import { mat4 } from "gl-matrix";
import upperJawImage from "../../../images/upper-jaw.png";
import lowerJawImage from "../../../images/lower-jaw.png";
import {
  AppGlobalDataContext,
  AnimationState,
} from "../../../providers/AppGlobalDataProvider";
import { formatDateFromString } from "../../../utils/dateTime";
import {
  ColorIndicator,
  JawImageContainer,
  SliderWrapper,
  ImageContainer,
  InfoContainer,
  DateTimeDisplay,
  ColorManipulationWrapper,
  ImportButton,
  IconContainer,
  StyledJawButton,
  InactiveScansMenu,
  SlidersContainer,
} from "./ColorManipulation.styled";
import RangeSlider from "../RangeSlider/RangeSlider";
import { NormalMesh } from "../../../model/Mesh";
import vtkGenericRenderWindow from "@kitware/vtk.js/Rendering/Misc/GenericRenderWindow";
import ImportDataDialogBox from "../ImportDataDialogBox/ImportDataDialogBox";
import ImportIcon from "../../svg-icons/ImportIcon";
import JawIconForAnimation from "../../svg-icons/JawIconForAnimation";
import MaxillaryIcon from "../../svg-icons/MaxillaryIcon";
import MandibularIcon from "../../svg-icons/MandibularIcon";
import { OccluUtil } from "../../../utils/OccluUtil";
import { ViewOrientation } from "../../../utils/common";
import ArrowDown from "../../svg-icons/ArrowDown";
import ArrowUp from "../../svg-icons/ArrowUp";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import Tooltip from "../Tooltip/Tooltip";

export interface IValues {
  [key: string]: {
    opacity: number;
    color: string;
  };
}

export interface IColorManipulationToolProps {
  genericRenderWindow?: vtkGenericRenderWindow | null;
  setCameraOrientation?: React.Dispatch<React.SetStateAction<ViewOrientation>>;
}

type Jawtype = "Occlusal" | "Maxillary" | "Mandibular";

export const ColorManipulationTool: React.FC<IColorManipulationToolProps> = ({
  setCameraOrientation,
  genericRenderWindow,
}: IColorManipulationToolProps) => {
  const {
    normalMeshes,
    opacityValues,
    setOpacityValues,
    groupedMeshesByDicomId,
    setGroupedMeshesByDicomId,
    animationState,
    isCommentModeActive,
    setActiveNormalMeshes,
    translations,
  } = useContext(AppGlobalDataContext);
  const normalMeshesMapRef = useRef<{ [key: string]: NormalMesh }>({});
  const [upperMeshes, setUpperMeshes] = useState<NormalMesh[]>([]);
  const [lowerMeshes, setLowerMeshes] = useState<NormalMesh[]>([]);
  const [upperMeshesInitialMatrix, setUpperMeshesInitialMatrix] =
    useState<mat4>();
  const [importDataDialogOpened, setImportDataDialogOpened] =
    useState<boolean>(false);
  const [activeJawVisibility, setActiveJawVisibility] = useState<Jawtype | "">(
    ""
  );
  const [isInactiveScansMenuOpen, setIsInactiveScansMenuOpen] =
    useState<boolean>(false);
  const previousActiveJaw = useRef<string>("");
  const appInsights = useAppInsightsContext();

  useEffect(() => {
    normalMeshesMapRef.current = {};
    const upMeshes: NormalMesh[] = [];
    const lowMeshes: NormalMesh[] = [];

    normalMeshes.forEach((normalMesh) => {
      normalMeshesMapRef.current[normalMesh.id] = normalMesh;

      if (normalMesh.name.includes("Maxillary")) {
        upMeshes.push(normalMesh);
      } else if (normalMesh.name.includes("Mandibular")) {
        lowMeshes.push(normalMesh);
      }
    });

    if (upMeshes.length) {
      setUpperMeshesInitialMatrix(
        new Float32Array(upMeshes[0].actor.getMatrix())
      );
    }

    setUpperMeshes(upMeshes);
    setLowerMeshes(lowMeshes);
  }, [normalMeshes]);

  useEffect(() => {
    return () => {
      upperMeshesInitialMatrix &&
        upperMeshes.forEach((mesh) =>
          mesh.actor.setUserMatrix(upperMeshesInitialMatrix)
        );
    };
  }, [upperMeshes, upperMeshesInitialMatrix]);

  const setJawsVisibility = useCallback(() => {
    if (activeJawVisibility === "Maxillary") {
      upperMeshes.forEach(
        (mesh) => mesh.isActive && mesh.actor.setVisibility(true)
      );
      lowerMeshes.forEach(
        (mesh) => mesh.isActive && mesh.actor.setVisibility(false)
      );
    } else if (activeJawVisibility === "Mandibular") {
      upperMeshes.forEach(
        (mesh) => mesh.isActive && mesh.actor.setVisibility(false)
      );
      lowerMeshes.forEach(
        (mesh) => mesh.isActive && mesh.actor.setVisibility(true)
      );
    } else if (activeJawVisibility === "Occlusal") {
      upperMeshes.forEach(
        (mesh) => mesh.isActive && mesh.actor.setVisibility(true)
      );
      lowerMeshes.forEach(
        (mesh) => mesh.isActive && mesh.actor.setVisibility(true)
      );
    } else {
      upperMeshes.forEach(
        (mesh) => mesh.isActive && mesh.actor.setVisibility(true)
      );
      lowerMeshes.forEach(
        (mesh) => mesh.isActive && mesh.actor.setVisibility(true)
      );
    }
  }, [activeJawVisibility, upperMeshes, lowerMeshes]);

  // Toggle visibility and track event
  const toggleJawVisibility = (jawType: Jawtype) => {
    appInsights.trackEvent({ name: `${jawType} view clicked` });
    setActiveJawVisibility(activeJawVisibility !== jawType ? jawType : "");
  };

  // Track import button click and open the dialog
  const handleImportClick = () => {
    appInsights.trackEvent({ name: "Import new data button clicked" });
    setImportDataDialogOpened(true);
  };

  useEffect(() => {
    if (!genericRenderWindow) return;
    const renderer = genericRenderWindow.getRenderer();

    // Set matrix
    const matrix =
      activeJawVisibility === "Occlusal"
        ? OccluUtil.createSpreadUpTransform(
            upperMeshes[0].actor,
            lowerMeshes[0].actor
          )
        : upperMeshesInitialMatrix;
    matrix &&
      upperMeshes.forEach((mesh) => {
        mesh.actor.setUserMatrix(matrix);
      });

    // Set visibility
    setJawsVisibility();

    // Set camera
    if (activeJawVisibility === "Occlusal") {
      setCameraOrientation && setCameraOrientation(ViewOrientation.Top);
      renderer.resetCamera();
    }

    if (previousActiveJaw.current === "Occlusal") {
      setCameraOrientation && setCameraOrientation(ViewOrientation.Front);
      renderer.resetCamera();
    }

    previousActiveJaw.current = activeJawVisibility;

    genericRenderWindow.getRenderWindow().render();
  }, [
    genericRenderWindow,
    upperMeshesInitialMatrix,
    activeJawVisibility,
    upperMeshes,
    lowerMeshes,
    setCameraOrientation,
    setJawsVisibility,
  ]);

  const updateOpacity = useCallback(
    (id: string, opacity: number) => {
      if (!genericRenderWindow) {
        return;
      }
      const renderWindow = genericRenderWindow.getRenderWindow();
      setOpacityValues((prevState) => {
        const newState = { ...prevState };
        newState[id] = opacity;
        return newState;
      });
      const normalMesh = normalMeshesMapRef.current[id];
      if (normalMesh !== undefined) {
        normalMesh.setOpacity(opacity);
        renderWindow?.render();
      }
    },
    [genericRenderWindow, setOpacityValues]
  );

  const handleChange = useCallback(
    (newValue: number, meshId: string) => {
      if (animationState !== AnimationState.Stopped) return;
      updateOpacity(meshId, newValue);
    },
    [updateOpacity, animationState]
  );

  const handleImageClick = useCallback(
    (meshId: string) => {
      if (animationState !== AnimationState.Stopped) return;
      const newOpacity = opacityValues[meshId] === 100 ? 0 : 100;
      updateOpacity(meshId, newOpacity);
    },
    [updateOpacity, opacityValues, animationState]
  );

  const activeGroupedMeshes = useMemo(() => {
    if (!groupedMeshesByDicomId) return;

    return Object.entries(groupedMeshesByDicomId).filter(
      ([, { isActive }]) => isActive
    );
  }, [groupedMeshesByDicomId]);

  const inactiveGroupedMeshes = useMemo(() => {
    if (!groupedMeshesByDicomId) return;

    return Object.entries(groupedMeshesByDicomId).filter(
      ([, { isActive }]) => !isActive
    );
  }, [groupedMeshesByDicomId]);

  const toggleScanActivity = (dicomId: string) => {
    if (!groupedMeshesByDicomId || !genericRenderWindow) return;

    const isClickedScanActive = groupedMeshesByDicomId[dicomId].isActive;

    normalMeshes.forEach(
      (mesh) =>
        mesh.scanId === dicomId && mesh.setIsActive(!isClickedScanActive)
    );

    setGroupedMeshesByDicomId((prevState) => {
      const newState = { ...prevState };
      newState[dicomId].isActive = !isClickedScanActive;
      return newState;
    });
    setActiveNormalMeshes(normalMeshes.filter((m) => m.isActive));
    setJawsVisibility();
    genericRenderWindow?.getRenderWindow().render();
  };

  return (
    <>
      {!isCommentModeActive && (
        <ColorManipulationWrapper>
          <IconContainer>
            <StyledJawButton
              $isActive={activeJawVisibility === "Occlusal"}
              $activeJawVisibility={activeJawVisibility}
              onClick={() => toggleJawVisibility("Occlusal")}
            >
              <JawIconForAnimation />
              {translations["OCCLUSAL"]}
            </StyledJawButton>
            <StyledJawButton
              $isActive={activeJawVisibility === "Maxillary"}
              $activeJawVisibility={activeJawVisibility}
              onClick={() => toggleJawVisibility("Maxillary")}
            >
              <MaxillaryIcon />
              {translations["MAXILLARY"]}
            </StyledJawButton>
            <StyledJawButton
              $isActive={activeJawVisibility === "Mandibular"}
              $activeJawVisibility={activeJawVisibility}
              onClick={() => toggleJawVisibility("Mandibular")}
            >
              <MandibularIcon />
              {translations["MANDIBULAR"]}
            </StyledJawButton>
          </IconContainer>

          <SlidersContainer>
            {!!activeGroupedMeshes?.length &&
              activeGroupedMeshes.map(
                ([dicomId, { meshes, isActive }], index) => {
                  const color = meshes[0]?.preferColorHex || "#ffffff";
                  const filteredArches = meshes.filter((mesh) =>
                    (mesh.name.includes("Maxillary") &&
                      activeJawVisibility === "Mandibular") ||
                    (mesh.name.includes("Mandibular") &&
                      activeJawVisibility === "Maxillary")
                      ? false
                      : true
                  );
                  return (
                    <JawImageContainer
                      key={`group-${index}`}
                      $isDisabled={animationState !== AnimationState.Stopped}
                    >
                      <InfoContainer
                        $isDisabled={animationState !== AnimationState.Stopped}
                      >
                        <Tooltip title={translations["CLICK_TO_HIDE_SCANS"]}>
                          <ColorIndicator
                            onClick={() => toggleScanActivity(dicomId)}
                            $color={color}
                            $isActive={isActive}
                          />
                        </Tooltip>
                        <DateTimeDisplay>
                          {formatDateFromString(meshes[0].acquisitionDateTime)}
                        </DateTimeDisplay>
                      </InfoContainer>
                      {filteredArches
                        .sort((el) => (el.name.includes("Maxillary") ? -1 : 1))
                        .map((arch, index) => {
                          const isFirstOfGroup =
                            index === 0 ||
                            meshes[index - 1].scanId !== arch.scanId;
                          const isLastOfGroup =
                            index === meshes.length - 1 ||
                            meshes[index + 1].scanId !== arch.scanId;
                          return (
                            <SliderWrapper
                              key={arch.id}
                              $isFirst={isFirstOfGroup}
                              $isLast={isLastOfGroup}
                            >
                              <ImageContainer
                                src={
                                  arch.name.includes("Maxillary")
                                    ? upperJawImage
                                    : lowerJawImage
                                }
                                alt={`${arch.name} Jaw`}
                                onClick={() => handleImageClick(arch.id)}
                              />
                              <RangeSlider
                                min={0}
                                max={100}
                                value={opacityValues[arch.id]}
                                color={arch.preferColorHex}
                                onChange={(newValue) =>
                                  handleChange(newValue, arch.id)
                                }
                              />
                            </SliderWrapper>
                          );
                        })}
                    </JawImageContainer>
                  );
                }
              )}
            {!!inactiveGroupedMeshes?.length && (
              <InactiveScansMenu>
                <p
                  onClick={() =>
                    setIsInactiveScansMenuOpen(!isInactiveScansMenuOpen)
                  }
                >
                  {isInactiveScansMenuOpen ? <ArrowDown /> : <ArrowUp />}{" "}
                  {translations["HIDDEN_SCANS"]}
                </p>
                {isInactiveScansMenuOpen &&
                  inactiveGroupedMeshes.map(
                    ([dicomId, { meshes, isActive }], index) => (
                      <InfoContainer
                        key={index}
                        $isDisabled={animationState !== AnimationState.Stopped}
                      >
                        <ColorIndicator
                          onClick={() => toggleScanActivity(dicomId)}
                          $color={meshes[0].preferColorHex || "#ffffff"}
                          $isActive={isActive}
                        />
                        <DateTimeDisplay>
                          {formatDateFromString(meshes[0].acquisitionDateTime)}
                        </DateTimeDisplay>
                      </InfoContainer>
                    )
                  )}
              </InactiveScansMenu>
            )}
          </SlidersContainer>

          <ImportButton
            onClick={handleImportClick}
            $isDisabled={animationState !== AnimationState.Stopped}
          >
            <ImportIcon />
            {translations["IMPORT_NEW_DATA"]}
          </ImportButton>
        </ColorManipulationWrapper>
      )}
      {importDataDialogOpened && (
        <ImportDataDialogBox onClose={() => setImportDataDialogOpened(false)} />
      )}
    </>
  );
};

export default ColorManipulationTool;
