import React from "react";
import { StyledTooltip } from "./Tooltip.styled";

type TooltipProps = {
  children: React.ReactNode;
  title: string;
  className?: string;
  id?: string;
};

const Tooltip: React.FC<TooltipProps> = ({
  children,
  title,
  className,
  id,
}) => {
  return (
    <StyledTooltip
      title={title}
      followCursor={true}
      placement={"bottom-start"}
      className={className}
      id={id}
    >
      {children}
    </StyledTooltip>
  );
};

export default Tooltip;
