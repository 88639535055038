import React, { useContext, useState } from "react";
import {
  StyledLeftViewPage,
  DualViewDivider,
  DisclaimerMessage,
  AttentionHighlight,
} from "./Measurement.styled";
import { StyledRightViewPage } from "./Measurement.styled";
import { MeasurementLeftViewer } from "../../model/Measurement/MeasurementLeftViewer";
import { SliceViewer } from "../../model/Measurement/SliceViewer";
import MeasurementMeshSelector from "../shared/Measurement/MeasurementMeshSelector";
import MeasurementPanel from "../shared/Measurement/MeasurementPanel";
import { RGBColor } from "@kitware/vtk.js/types";
import UpperLowerSwitch from "../shared/UpperLowerSwitch/UpperLowerSwitch";
import vtkRenderer from "@kitware/vtk.js/Rendering/Core/Renderer";
import vtkGenericRenderWindow from "@kitware/vtk.js/Rendering/Misc/GenericRenderWindow";
import GenericToolBar from "../shared/GenericToolBar/GenericToolBar";
import vtkPolyData from "@kitware/vtk.js/Common/DataModel/PolyData";
import MeasurementManager from "../../utils/MeasurementManager";
import { Measurement } from "../../model/Measurement/Measurement";
import { AppGlobalDataContext } from "../../providers/AppGlobalDataProvider";
import SliceViewerHandler from "../../model/Measurement/SliceViewerHandler";

export interface IMeasurementMeshVisibleState {
  id: string;
  scanId: string;
  index: number;
  visible: boolean;
}

export interface ISliceData {
  scanId: string;
  polydata: vtkPolyData;
}

interface IMeasurementContext {
  sliceData: any;
  sliceColor: RGBColor[];
  setSliceColor: React.Dispatch<React.SetStateAction<RGBColor[]>>;
  setSliceData: React.Dispatch<React.SetStateAction<any>>;
  meshVisibleStatus: IMeasurementMeshVisibleState[];
  setMeshVisibleStatus?: React.Dispatch<
    React.SetStateAction<IMeasurementMeshVisibleState[]>
  >;
  upperVisible: boolean;
  setUpperVisible: React.Dispatch<React.SetStateAction<boolean>>;
  lowerVisible: boolean;
  setLowerVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setMultipleGenericRenderWindows: React.Dispatch<
    React.SetStateAction<vtkGenericRenderWindow[]>
  >;
  setMultipleRenderers: React.Dispatch<React.SetStateAction<vtkRenderer[]>>;
  measurementManager: MeasurementManager | null;
  setMeasurementManager: React.Dispatch<
    React.SetStateAction<MeasurementManager | null>
  >;
  measurementOnFocus: Measurement | null;
  setMeasurementOnFocus: React.Dispatch<
    React.SetStateAction<Measurement | null>
  >;
  isCrossSectionUpdating: boolean;
  setIsCrossSectionUpdating: React.Dispatch<React.SetStateAction<boolean>>;
  isMeasurementEnabled: boolean;
  setIsMeasurementEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  isCreatingMeasurement: boolean;
  setIsCreatingMeasurement: React.Dispatch<React.SetStateAction<boolean>>;
  sliceViewerHandler: SliceViewerHandler;
}

const MeasurementContext = React.createContext<IMeasurementContext>({
  sliceData: {},
  setSliceData: () => {},
  meshVisibleStatus: [],
  sliceColor: [],
  setSliceColor: () => {},
  upperVisible: true,
  setUpperVisible: () => {},
  lowerVisible: true,
  setLowerVisible: () => {},
  setMultipleGenericRenderWindows: () => {},
  setMultipleRenderers: () => {},
  measurementManager: null,
  setMeasurementManager: () => {},
  measurementOnFocus: null,
  setMeasurementOnFocus: () => {},
  isCrossSectionUpdating: false,
  setIsCrossSectionUpdating: () => {},
  isMeasurementEnabled: false,
  setIsMeasurementEnabled: () => {},
  isCreatingMeasurement: false,
  setIsCreatingMeasurement: () => {},
  sliceViewerHandler: {} as SliceViewerHandler,
});

const MeasurementLayout: React.FC = () => {
  const [meshVisibleStatus, setMeshVisibleStatus] = useState<
    IMeasurementMeshVisibleState[]
  >([]);
  const [sliceData, setSliceData] = useState<any>({});
  const [sliceColor, setSliceColor] = useState<RGBColor[]>([]);
  const [upperVisible, setUpperVisible] = useState<boolean>(true);
  const [lowerVisible, setLowerVisible] = useState<boolean>(true);
  const [multipleGenericRenderWindows, setMultipleGenericRenderWindows] =
    useState<vtkGenericRenderWindow[]>([]);
  const [multipleRenderers, setMultipleRenderers] = useState<vtkRenderer[]>([]);
  const [measurementManager, setMeasurementManager] =
    useState<MeasurementManager | null>(null);
  const [measurementOnFocus, setMeasurementOnFocus] =
    useState<Measurement | null>(null);
  const [isCrossSectionUpdating, setIsCrossSectionUpdating] =
    useState<boolean>(false);
  const [isMeasurementEnabled, setIsMeasurementEnabled] =
    useState<boolean>(false);
  const [isCreatingMeasurement, setIsCreatingMeasurement] =
    useState<boolean>(false);
  const { translations } = useContext(AppGlobalDataContext);
  const [sliceViewerHandler] = useState<SliceViewerHandler>(
    new SliceViewerHandler()
  );

  const context: IMeasurementContext = {
    sliceData,
    setSliceData,
    meshVisibleStatus,
    setMeshVisibleStatus,
    sliceColor,
    setSliceColor,
    upperVisible,
    setUpperVisible,
    lowerVisible,
    setLowerVisible,
    setMultipleGenericRenderWindows,
    setMultipleRenderers,
    measurementManager,
    setMeasurementManager,
    measurementOnFocus,
    setMeasurementOnFocus,
    isCrossSectionUpdating,
    setIsCrossSectionUpdating,
    isMeasurementEnabled,
    setIsMeasurementEnabled,
    isCreatingMeasurement,
    setIsCreatingMeasurement,
    sliceViewerHandler,
  };

  return (
    <MeasurementContext.Provider value={context}>
      <GenericToolBar
        multipleGenericRenderWindows={multipleGenericRenderWindows}
        multipleRenderers={multipleRenderers}
        withTrueColor={false}
        withSnapshot={true}
        withViewOrientation={false}
        withComments={false}
      />
      <StyledLeftViewPage>
        <div>
          <MeasurementLeftViewer />
        </div>
        <UpperLowerSwitch
          upperVisible={upperVisible}
          lowerVisible={lowerVisible}
          upperVisibleChange={() => {
            setUpperVisible(!upperVisible);
          }}
          lowerVisibleChange={() => {
            setLowerVisible(!lowerVisible);
          }}
        />
      </StyledLeftViewPage>

      <DualViewDivider />

      <StyledRightViewPage id="rightMeasurementContainer">
        <DisclaimerMessage>
          <AttentionHighlight>{translations["ATTENTION"]}</AttentionHighlight>
          {translations["MEASUREMENT_IS_ONLY_FOR_INDUCTIVE_USE"]}
          {"\n"}
          {translations["BITE_REGISTRATION_IS_ALL_RELYING_ON_THE_LATEST_SCAN"]}
          {"\n"}
          {translations["PLEASE_BE_MINDFUL_OF_THE_OCCLUSION_CHANGE"]}
        </DisclaimerMessage>
        <SliceViewer />
        <MeasurementMeshSelector />
        <MeasurementPanel />
      </StyledRightViewPage>
    </MeasurementContext.Provider>
  );
};

export { MeasurementLayout, MeasurementContext };
