import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  AddMeasurementButton,
  ButtonsContainer,
  CancelButton,
  DeleteButton,
  DeleteMeasurementDialog,
  DialogTitle,
  MeasurementList,
  MeasurementListItem,
  MeasurementsWrapper,
  MirrorButton,
  PanelTitle,
  PromptButtonsContainer,
  PromptContainer,
} from "./MeasurementPanel.styled";
import { AppGlobalDataContext } from "../../../providers/AppGlobalDataProvider";
import MeasurementRulerIcon from "../../svg-icons/MeasurementRulerIcon";
import { Measurement } from "../../../model/Measurement/Measurement";
import { MeasurementContext } from "../../layouts/Measurement";
import { useClickOutsideOfComponent } from "../../../utils/useClickOutsideOfComponent";
import TrashIcon from "../../svg-icons/TrashIcon";
import { ActionButton } from "../Comment/CommentPanel.styled";
import WarningIcon from "../../svg-icons/WarningIcon";
import MirrorIcon from "../../svg-icons/MirrorIcon";
import AddMeasurementRulerIcon from "../../svg-icons/AddMeasurementRulerIcon";
import { CREATE_MEASUREMENT_EVENT, publish } from "../../../utils/event.js";
import Tooltip from "../Tooltip/Tooltip";

export const MeasurementPanel = () => {
  const { measurements, setMeasurements, translations } =
    useContext(AppGlobalDataContext);
  const {
    measurementManager,
    measurementOnFocus,
    setMeasurementOnFocus,
    isCreatingMeasurement,
    isMeasurementEnabled,
    sliceViewerHandler,
  } = useContext(MeasurementContext);

  const [showDeleteMeasurementPrompt, setShowDeleteMeasurementPrompt] =
    useState(false);

  // State to track which measurement to delete
  const [measurementToDeleteIndex, setMeasurementToDeleteIndex] = useState<
    number | null
  >(null);

  // Remove focused measurement when clicking elsewhere
  const measurementsListRef = useRef<HTMLUListElement>(null);
  useClickOutsideOfComponent(
    measurementsListRef,
    () => setMeasurementOnFocus(null),
    false
  );

  const handleMeasurementFocus = (measurement: Measurement) => {
    setMeasurementOnFocus(
      measurementOnFocus === measurement ? null : measurement
    );
  };

  const handleDeleteMeasurementPrompt = (indexToDelete: number) => {
    setMeasurementToDeleteIndex(indexToDelete);
    setShowDeleteMeasurementPrompt(true);
  };

  const handleDeleteMeasurement = () => {
    if (measurementToDeleteIndex === null) return;

    const measurementWidgetToDelete =
      measurements[measurementToDeleteIndex].widgetHandle;

    const updatedMeasurements = measurements.filter(
      (_, index) => index !== measurementToDeleteIndex
    );
    setMeasurements(updatedMeasurements);

    measurementManager?.clearLineWidget(measurementWidgetToDelete!);
    setShowDeleteMeasurementPrompt(false);
    setMeasurementToDeleteIndex(null);
  };

  useEffect(() => {
    if (measurementManager) {
      measurementManager.highlightWidget(measurementOnFocus?.widgetHandle);
    }
  }, [measurementManager, measurementOnFocus]);

  const handleAddMeasurement = useCallback(() => {
    publish(CREATE_MEASUREMENT_EVENT, {});
  }, []);

  // Activate add measurement by default if needed
  useEffect(() => {
    if (
      isMeasurementEnabled &&
      !isCreatingMeasurement &&
      measurements.length === 0
    ) {
      handleAddMeasurement();
    }
  }, [
    isMeasurementEnabled,
    isCreatingMeasurement,
    measurements,
    handleAddMeasurement,
  ]);

  const handleMirroring = useCallback(() => {
    const renderer = sliceViewerHandler.getRenderer();
    const camera = renderer.getActiveCamera();
    const position = camera.getPosition();
    camera.setPosition(position[0], -position[1], position[2]);
    renderer.getRenderWindow()?.render();
  }, [sliceViewerHandler]);

  return (
    <MeasurementsWrapper>
      <PanelTitle>{translations["HISTORY"]}</PanelTitle>
      <MeasurementList ref={measurementsListRef}>
        {measurements.map((measurement, index) => (
          <MeasurementListItem
            key={index}
            onClick={() => handleMeasurementFocus(measurement)}
            $focus={measurement === measurementOnFocus}
          >
            <MeasurementRulerIcon />
            {measurement.getDistanceText()}
            <ActionButton onClick={() => handleDeleteMeasurementPrompt(index)}>
              <TrashIcon />
            </ActionButton>
          </MeasurementListItem>
        ))}
      </MeasurementList>
      {isMeasurementEnabled && (
        <ButtonsContainer>
          <AddMeasurementButton
            onClick={handleAddMeasurement}
            $toggled={isCreatingMeasurement}
          >
            <AddMeasurementRulerIcon />
            {translations["MEASURE"]}
          </AddMeasurementButton>
          <Tooltip title={translations["MIRROR"]}>
            <MirrorButton onClick={handleMirroring}>
              <MirrorIcon />
            </MirrorButton>
          </Tooltip>
        </ButtonsContainer>
      )}
      {showDeleteMeasurementPrompt && (
        <DeleteMeasurementDialog open={showDeleteMeasurementPrompt}>
          <PromptContainer>
            <WarningIcon />
            <DialogTitle>Delete Measurement</DialogTitle>
            <p>You are about to DELETE the selected measurement!</p>
            <PromptButtonsContainer>
              <CancelButton
                onClick={(e: { stopPropagation: () => void }) => {
                  e.stopPropagation();
                  setShowDeleteMeasurementPrompt(false);
                }}
              >
                {translations["CANCEL"]}
              </CancelButton>
              <DeleteButton onClick={handleDeleteMeasurement}>
                {translations["DELETE"]}
              </DeleteButton>
            </PromptButtonsContainer>
          </PromptContainer>
        </DeleteMeasurementDialog>
      )}
    </MeasurementsWrapper>
  );
};

export default MeasurementPanel;
