import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import { Fill, SliderWrapper, Thumb } from "./RangeSlider.styled";

interface RangeSliderProps {
  min: number;
  max: number;
  onChange: (value: number) => void;
  color: string;
  value: number;
}

const RangeSlider: React.FC<RangeSliderProps> = ({
  min = 0,
  max = 100,
  onChange,
  color,
  value,
}) => {
  const [latestValue, setLatestValue] = useState<number>(value);
  const sliderRef = useRef<HTMLDivElement | null>(null);

  const position = useMemo(
    () => ((latestValue - min) / (max - min)) * 100,
    [latestValue, min, max]
  );

  useEffect(() => {
    setLatestValue(value);
  }, [value]);

  const updateSliderValue = useCallback(
    (event: any) => {
      if (!sliderRef.current || !onChange) return;

      const sliderRect = sliderRef.current.getBoundingClientRect();
      const newPosition = Math.min(
        sliderRect.width,
        Math.max(0, event.clientX - sliderRect.left)
      );
      const newValue = Math.round(
        (newPosition / sliderRect.width) * (max - min) + min
      );
      setLatestValue(newValue);
      onChange(newValue);
    },
    [min, max, onChange]
  );

  const startDrag = () => {
    document.addEventListener("mousemove", updateSliderValue);
    document.addEventListener("mouseup", stopDrag);
  };

  const stopDrag = () => {
    document.removeEventListener("mousemove", updateSliderValue);
    document.removeEventListener("mouseup", stopDrag);
  };

  return (
    <SliderWrapper
      ref={sliderRef}
      onMouseDown={startDrag}
      onClick={updateSliderValue}
    >
      <Fill $color={color} $position={position} />
      <Thumb onMouseDown={startDrag} $color={color} $position={position} />
    </SliderWrapper>
  );
};

export default RangeSlider;
