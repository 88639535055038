import React from "react";

const PauseIcon: React.FC = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 34C26.8366 34 34 26.8366 34 18C34 9.16344 26.8366 2 18 2C9.16344 2 2 9.16344 2 18C2 26.8366 9.16344 34 18 34ZM18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36ZM11 12C11 10.8954 11.8954 10 13 10C14.1046 10 15 10.8954 15 12V24C15 25.1046 14.1046 26 13 26C11.8954 26 11 25.1046 11 24V12ZM23 10C21.8954 10 21 10.8954 21 12V24C21 25.1046 21.8954 26 23 26C24.1046 26 25 25.1046 25 24V12C25 10.8954 24.1046 10 23 10Z"
        fill="#4D4D4D"
      />
    </svg>
  );
};

export default PauseIcon;
