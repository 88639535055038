import styled, { keyframes } from "styled-components";
import { colors } from "../../../theme";

const upperJawAnimation = keyframes`
  0% { 
    transform: rotate(0deg); 
  }
  30% { 
    transform: rotate(-15deg); 
  }
  70% { 
    transform: rotate(-15deg); 
  }
  100% {
    transform: rotate(0deg); 
  }
`;

const lowerJawAnimation = keyframes`
  0% { 
    transform: rotate(0deg); 
  }
  30% { 
    transform: rotate(15deg); 
  }
  70% { 
    transform: rotate(15deg); 
  }
  100% {
    transform: rotate(0deg); 
  }
`;

export const ColorManipulationWrapper = styled.div`
  position: fixed;
  right: 20px;
  top: 280px;
  display: flex;
  flex-direction: column;
  width: 380px;
  height: calc(100vh - 350px);
  padding: 15px 0 15px 15px;
  border: 1px solid ${colors["gray-1"]};
  border-radius: 10px 0px 0px 10px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px solid ${colors["gray-2"]};
  padding-bottom: 10px;
  margin-bottom: 10px;
  margin-right: 15px;
`;

export const SlidersContainer = styled.div`
  padding-right: 15px;
  margin-bottom: 10px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    background-clip: padding-box;
    border-radius: 10px;
    background-color: ${colors["gray-1"]};
  }
`;

export const JawImageContainer = styled.div<{ $isDisabled: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 1px;
  align-items: start;
  padding-bottom: 1px;
  margin: 12px 0;
  opacity: ${({ $isDisabled }) => ($isDisabled ? 0.5 : 1)};
  pointer-events: ${({ $isDisabled }) => ($isDisabled ? "none" : "auto")};
  filter: ${({ $isDisabled }) => ($isDisabled ? "grayscale" : "none")};
`;

export const InfoContainer = styled.div<{ $isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  margin-left: 70px;
  font-size: 22px;
  opacity: ${({ $isDisabled }) => ($isDisabled ? 0.5 : 1)};
  pointer-events: ${({ $isDisabled }) => ($isDisabled ? "none" : "auto")};
  filter: ${({ $isDisabled }) => ($isDisabled ? "grayscale" : "none")};
`;

export const DateTimeDisplay = styled.span`
  margin: 0 10px;
  white-space: nowrap;
`;

type SliderWrapperProps = {
  $isFirst?: boolean;
  $isLast?: boolean;
};

export const SliderWrapper = styled.div<SliderWrapperProps>`
  display: flex;
  align-items: center;
  gap: 1px;
  width: 100%;
  padding-right: 10px;
  margin-top: ${(props) => (props.$isFirst ? "10px" : "0px")};
  margin-bottom: ${(props) => (props.$isLast ? "10px" : "0px")};
`;

export const ColorIndicator = styled.div<{
  $color: string;
  $isActive: boolean;
  $readOnly?: boolean;
}>`
  appearance: none;
  position: relative;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border: 4px solid ${(props) => props.$color};
  border-radius: 4px;
  outline: none;
  background-color: ${(props) =>
    props.$isActive ? props.$color : "transperant"};
  cursor: ${(props) => (props.$readOnly ? "default" : "pointer")};

  ${(props) =>
    props.$isActive &&
    !props.$readOnly &&
    `&::after {
      content: "✔";
      color: white;
      font-size: 22px;
      position: absolute;
      top: -5px;
      left: 3px;
    }`}
`;

export const ImageContainer = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 20px;
`;

export const StyledJawButton = styled.button<{
  $isActive: boolean;
  $activeJawVisibility: string;
}>`
  display: flex;
  align-items: center;
  gap: 5px;
  height: 32px;
  padding: 0;
  border: none;
  color: ${(props) =>
    props.$isActive ? colors["blue-1"] : colors["dark-gray"]};
  background-color: transparent;
  font-size: 14px;
  font-weight: ${(props) => (props.$isActive ? 600 : 400)};
  outline: none;
  cursor: pointer;

  & svg #lowerYellowArrow {
    transform: translate(${(props) => (props.$isActive ? "-10%,-45%" : "")});
  }

  & svg #upperYellowArrow {
    transform: translate(${(props) => (props.$isActive ? "-10%, 45%" : "")});
  }

  & svg #upperJaw,
  svg #lowerJaw {
    & path {
      stroke: ${(props) => (props.$isActive ? colors["blue-1"] : "")};
    }
    & path:first-of-type {
      fill: ${(props) => (props.$isActive ? colors["blue-1"] : "")};
    }
  }

  & svg #maxillary,
  svg #mandibular {
    fill: ${(props) => (props.$isActive ? colors["blue-1"] : "")};
  }

  &:hover {
    color: ${(props) =>
      !props.$activeJawVisibility || props.$isActive
        ? colors["blue-1"]
        : colors["blue-3"]};

    & svg #upperJaw,
    svg #lowerJaw {
      animation: 2s ${upperJawAnimation} 0.3s infinite ease-in-out;
      transform-origin: 0 50%;

      & path {
        stroke: ${(props) =>
          !props.$activeJawVisibility || props.$isActive
            ? colors["blue-1"]
            : colors["blue-3"]};
      }
      & path:first-of-type {
        fill: ${(props) =>
          !props.$activeJawVisibility || props.$isActive
            ? colors["blue-1"]
            : colors["blue-3"]};
      }
    }

    & svg #lowerJaw {
      animation-name: ${lowerJawAnimation};
    }

    & svg #maxillary,
    svg #mandibular {
      fill: ${(props) =>
        !props.$activeJawVisibility || props.$isActive
          ? colors["blue-1"]
          : colors["blue-3"]};
    }
  }
`;

export const InactiveScansMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
  margin-bottom: 20px;
`;

export const ImportButton = styled.button<{ $isDisabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 270px;
  min-height: 60px;
  margin: auto auto 10px auto;
  border: 2px solid ${colors["gray-1"]};
  border-radius: 30px;
  background-color: transparent;
  color: ${colors["dark-gray"]};
  font-size: 22px;
  outline: none;
  transform: translateX(-7.5px);
  cursor: pointer;
  opacity: ${({ $isDisabled }) => ($isDisabled ? 0.5 : 1)};
  pointer-events: ${({ $isDisabled }) => ($isDisabled ? "none" : "auto")};
  filter: ${({ $isDisabled }) => ($isDisabled ? "grayscale" : "none")};

  &:hover {
    background-color: ${colors["dark-gray"]}15;
  }

  &:active {
    background-color: ${colors["dark-gray"]}30;
  }
`;
