import styled from "styled-components";
import { colors } from "../../../theme";

export const SliderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background-color: ${colors["gray-3"]};
  cursor: pointer;
  user-select: none;
`;

export const Thumb = styled.div<{ $color: string; $position: number }>`
  position: absolute;
  top: 50%;
  left: ${(props) => props.$position + "%"};
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${(props) => props.$color};
  transform: translate(-50%, -50%);
  cursor: pointer;
`;

export const Fill = styled.div<{ $color: string; $position: number }>`
  position: absolute;
  top: 50%;
  left: 0;
  width: ${(props) => props.$position + "%"};
  height: 7px;
  background-color: ${(props) => props.$color};
  border-radius: 5px;
  transform: translateY(-50%);
`;
