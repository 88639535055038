import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AppGlobalDataContext } from "../../providers/AppGlobalDataProvider";
import { formatDateFromString } from "../../utils/dateTime";
import {
  DateWrapper,
  DualViewDivider,
  DualViewLabels,
  StyledLayout,
  ViewportContainer,
} from "./OcclusionProximity.styled";
import GenericToolBar from "../shared/GenericToolBar/GenericToolBar";
import {
  NormalMesh,
  RenderingMode,
  createGroupedMeshes,
  findMeshPairList,
} from "../../model/Mesh";
import { vtkUtil } from "../../utils/vtkUtils";
import WasmWorkerClient from "../../workers/WasmWorkerClient";
import { OccluUtil, WasmCmdType } from "../../utils/OccluUtil";
import {
  occlusionMapWorker,
  computeOcclusionMap,
} from "../../utils/computeOcclusionMap";
import vtkLookupTable from "@kitware/vtk.js/Common/Core/LookupTable";
import OcclusionProximityRuler from "../shared/OcclusionProximityRuler/OcclusionProximityRuler";
import BusyDlg from "../shared/BusyDlg/BusyDlg";
import DialogBox, { GroupedMeshes } from "../shared/DialogBox/DialogBox";
import vtkGenericRenderWindow from "@kitware/vtk.js/Rendering/Misc/GenericRenderWindow";
import vtkRenderer from "@kitware/vtk.js/Rendering/Core/Renderer";
import ResetPNG from "../../images/swap.png";
import { ColorIndicator } from "../shared/ColorManipulationTool/ColorManipulation.styled";
import vtkInteractorStyleMeshView from "../../vtkExtension/InteractorStyleMeshView";
import vtkCamera from "@kitware/vtk.js/Rendering/Core/Camera";
import CommentPanel from "../shared/Comment/CommentPanel";
import { mat4 } from "gl-matrix";

class DcmMesh {
  acquisitionDate: string;
  upper: NormalMesh;
  lower: NormalMesh;
  preferColorHex: string;

  constructor(
    acquisitionDate: string,
    upper: NormalMesh,
    lower: NormalMesh,
    colorHex: string
  ) {
    this.acquisitionDate = acquisitionDate;
    this.upper = upper;
    this.lower = lower;
    this.preferColorHex = colorHex;
  }
}

class OcclusionMesh {
  prevMesh: DcmMesh;
  postMesh: DcmMesh;

  constructor(prev: DcmMesh, post: DcmMesh) {
    this.prevMesh = prev;
    this.postMesh = post;
  }
}

type chooseIndex = 0 | 1;

export const OcclusionProximity: React.FC = () => {
  const vtkContainerRefL = useRef(null);
  const vtkContainerRefR = useRef(null);
  const { activeNormalMeshes, translations, comments } =
    useContext(AppGlobalDataContext);
  const [occlusionMesh, setOcclusionMesh] = useState<OcclusionMesh>();
  const [computationState, setComputationState] = useState<{
    busy: boolean;
    done: boolean;
  }>({ busy: false, done: false });
  const [wasmWorker1Ready, setWasmWorker1Ready] = useState<boolean>(false);
  const [wasmWorker2Ready, setWasmWorker2Ready] = useState<boolean>(false);
  const wasmWorker1 = useRef<WasmWorkerClient | null>(null);
  const wasmWorker2 = useRef<WasmWorkerClient | null>(null);
  const [lookupTable] = useState<vtkLookupTable>(
    OccluUtil.createLutForOccluMapping()
  );
  const [genericRenderWindowL, setGenericRenderWindowL] =
    useState<vtkGenericRenderWindow>();
  const [genericRenderWindowR, setGenericRenderWindowR] =
    useState<vtkGenericRenderWindow>();
  const [multipleRenderers, setMultipleRenderers] = useState<vtkRenderer[]>();
  const { isCommentModeActive } = useContext(AppGlobalDataContext);
  const [selectDialogOpened, setSelectDialogOpened] = useState<boolean>(false);
  const [selectScanIds, setSelectScanIds] = useState<string[]>([]);
  const [groupedMeshes, setGroupedMeshes] = useState<GroupedMeshes>({
    upperJawMeshes: [],
    lowerJawMeshes: [],
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [shiftedContentWithCommentMode] = useState<number>(300);
  const [upperJawInitialMatrix, setUpperJawInitialMatrix] = useState<mat4>();
  const [chooseIndex, setChooseIndex] = useState<chooseIndex | null>(null);

  useEffect(() => {
    if (
      activeNormalMeshes.length > 0 &&
      groupedMeshes.upperJawMeshes.length <= 0
    ) {
      setGroupedMeshes(createGroupedMeshes(activeNormalMeshes));
    }
  }, [activeNormalMeshes, groupedMeshes]);

  useEffect(() => {
    const ids = groupedMeshes.upperJawMeshes.map((m) => m.scanId);
    if (2 <= ids.length) {
      setSelectScanIds([ids[1], ids[0]]);
    }
  }, [groupedMeshes]);

  useEffect(() => {
    // restore the upper jaw matrixes upon component unmounting
    return () => {
      if (upperJawInitialMatrix && occlusionMesh) {
        occlusionMesh.prevMesh.upper.actor.setUserMatrix(upperJawInitialMatrix);
        occlusionMesh.postMesh.upper.actor.setUserMatrix(upperJawInitialMatrix);
      }
    };
  }, [upperJawInitialMatrix, occlusionMesh]);

  const handleDialogConfirm = useCallback(
    (scanIds: string[]) => {
      setSelectDialogOpened(false);
      if (null !== chooseIndex && scanIds.length > 0) {
        const selectedId = scanIds.pop() as string;
        const ids = [...selectScanIds];
        if (!ids.includes(selectedId)) {
          ids[chooseIndex] = selectedId;
          setSelectScanIds(ids);
          setOcclusionMesh(undefined);
          setComputationState({ busy: false, done: false });
        } else if (ids[chooseIndex] === selectedId) {
          return;
        } else {
          ids[chooseIndex] = selectedId;
          setSelectScanIds(ids);
          setOcclusionMesh((prevState) => {
            if (0 === chooseIndex) {
              return new OcclusionMesh(
                prevState!.postMesh,
                prevState!.postMesh
              );
            } else {
              return new OcclusionMesh(
                prevState!.prevMesh,
                prevState!.prevMesh
              );
            }
          });
        }
      }
    },
    [chooseIndex, selectScanIds]
  );

  useEffect(() => {
    wasmWorker1.current = new WasmWorkerClient();
    wasmWorker1.current.onmessage = (e) => {
      if (WasmCmdType.InitWasmSuccess === e?.data) {
        setWasmWorker1Ready(true);
      }
    };
    wasmWorker1.current.postMessage({ type: WasmCmdType.InitWasm });

    wasmWorker2.current = new WasmWorkerClient();
    wasmWorker2.current.onmessage = (e) => {
      if (WasmCmdType.InitWasmSuccess === e?.data) {
        setWasmWorker2Ready(true);
      }
    };
    wasmWorker2.current.postMessage({ type: WasmCmdType.InitWasm });
  }, []);

  useEffect(() => {
    if (
      wasmWorker1.current &&
      wasmWorker1Ready &&
      wasmWorker2.current &&
      wasmWorker2Ready
    ) {
      occlusionMapWorker.setWorker(wasmWorker1.current, wasmWorker2.current);
    }
  }, [wasmWorker1Ready, wasmWorker2Ready]);

  const updateScalars = useCallback(
    async (
      mesh: DcmMesh,
      scalars: { upperScalars: Float32Array; lowerScalars: Float32Array }
    ) => {
      const range = OccluUtil.occluMappingRange();
      OccluUtil.setMappingScalars(
        mesh.upper.actor,
        scalars.upperScalars,
        range,
        lookupTable
      );
      OccluUtil.setMappingScalars(
        mesh.lower.actor,
        scalars.lowerScalars,
        range,
        lookupTable
      );
    },
    [lookupTable]
  );

  const handleOcclusionMapping = useCallback(
    async (occuMesh: OcclusionMesh) => {
      if (!wasmWorker1Ready || !wasmWorker2Ready || occlusionMesh == null) {
        return;
      }
      setIsLoading(true);
      console.time("Occlusion Mapping");
      let computeTasks: Promise<void>[] = [];

      // Process upper jaw meshes
      if (occuMesh.prevMesh.upper && occuMesh.prevMesh.lower) {
        const task = computeOcclusionMap(
          occuMesh.prevMesh.lower,
          occuMesh.prevMesh.upper
        ).then((scalars) => {
          updateScalars(occuMesh.prevMesh, scalars);
        });
        computeTasks.push(task);
      }

      if (occuMesh.postMesh.upper && occuMesh.postMesh.lower) {
        const task = computeOcclusionMap(
          occuMesh.postMesh.lower,
          occuMesh.postMesh.upper
        ).then((scalars) => {
          updateScalars(occuMesh.postMesh, scalars);
        });
        computeTasks.push(task);
      }
      await Promise.all(computeTasks);
      console.timeEnd("Occlusion Mapping");
      setComputationState({ busy: false, done: true });

      // Set meshes rendering mode
      if (occuMesh.prevMesh.upper) {
        occuMesh.prevMesh.upper.setRenderingMode(RenderingMode.Scalar);
        occuMesh.prevMesh.upper.setOpacity(100);
      }
      if (occuMesh.prevMesh.lower) {
        occuMesh.prevMesh.lower.setRenderingMode(RenderingMode.Scalar);
        occuMesh.prevMesh.lower.setOpacity(100);
      }
      if (occuMesh.postMesh.upper) {
        occuMesh.postMesh.upper.setRenderingMode(RenderingMode.Scalar);
        occuMesh.postMesh.upper.setOpacity(100);
      }
      if (occuMesh.postMesh.lower) {
        occuMesh.postMesh.lower.setRenderingMode(RenderingMode.Scalar);
        occuMesh.postMesh.lower.setOpacity(100);
      }
    },
    [occlusionMesh, wasmWorker1Ready, wasmWorker2Ready, updateScalars]
  );

  const initOcculusionMesh = useCallback(async () => {
    // Ensure that all meshes have a scanId property before proceeding
    const meshes = activeNormalMeshes.filter(
      (m) => m && m.scanId && selectScanIds.includes(m.scanId)
    );

    // sort as order in selectScanIds
    if (selectScanIds[0] !== meshes[0].scanId) {
      meshes.reverse();
    }
    const pairList = findMeshPairList(meshes);
    if (pairList.length < 2) {
      return;
    }
    const l_upper = pairList[0].upper;
    const l_lower = pairList[0].lower;
    const r_upper = pairList[1].upper;
    const r_lower = pairList[1].lower;
    const l_color = pairList[0].upper.preferColorHex;
    const r_color = pairList[1].upper.preferColorHex;
    const occuMesh = new OcclusionMesh(
      new DcmMesh(l_upper.acquisitionDateTime, l_upper, l_lower, l_color),
      new DcmMesh(r_upper.acquisitionDateTime, r_upper, r_lower, r_color)
    );
    setUpperJawInitialMatrix(new Float32Array(l_upper.actor.getMatrix()));
    setOcclusionMesh(occuMesh);
  }, [activeNormalMeshes, selectScanIds]);

  const initRenderWindow = useCallback(() => {
    if (genericRenderWindowL && genericRenderWindowR) {
      return;
    }
    // Initialize the render windows for left and right views
    const { renderWindowL, renderWindowR, rendererL, rendererR } =
      vtkUtil.createDualViewRenderWindow(vtkContainerRefL, vtkContainerRefR);

    const syncCamera = vtkCamera.newInstance();
    rendererL.setActiveCamera(syncCamera);
    rendererR.setActiveCamera(syncCamera);

    setGenericRenderWindowL(renderWindowL);
    setGenericRenderWindowR(renderWindowR);
    setMultipleRenderers([rendererL, rendererR]);

    // Initialize interactor style for both views
    const interactorL = renderWindowL.getInteractor();
    const interactorR = renderWindowR.getInteractor();
    interactorL.setInteractorStyle(vtkInteractorStyleMeshView.newInstance());
    interactorR.setInteractorStyle(vtkInteractorStyleMeshView.newInstance());
    vtkUtil.synchronizeCamerasBidirectionally(rendererL, rendererR);
  }, [
    vtkContainerRefL,
    vtkContainerRefR,
    genericRenderWindowL,
    genericRenderWindowR,
  ]);

  // Enable picker
  useEffect(() => {
    if (
      isLoading ||
      !genericRenderWindowL ||
      !genericRenderWindowR ||
      !occlusionMesh
    )
      return;
    const pickerL = genericRenderWindowL.getInteractor().getPicker();
    const pickerR = genericRenderWindowR.getInteractor().getPicker();
    if (!pickerL || !pickerR) return;

    pickerL.initializePickList();
    pickerR.initializePickList();
    pickerL.addPickList(occlusionMesh.prevMesh.upper.actor);
    pickerL.addPickList(occlusionMesh.prevMesh.lower.actor);
    pickerR.addPickList(occlusionMesh.postMesh.upper.actor);
    pickerR.addPickList(occlusionMesh.postMesh.lower.actor);

    //Add comment actors to the pick list
    comments.forEach((comment) => {
      pickerL.addPickList(comment.actor);
      pickerR.addPickList(comment.actor);
    });
  }, [
    isLoading,
    genericRenderWindowL,
    genericRenderWindowR,
    occlusionMesh,
    comments,
  ]);

  const renderCanvas = useCallback(async () => {
    if (!multipleRenderers || !genericRenderWindowL || !genericRenderWindowR)
      return;

    if (occlusionMesh && computationState.done) {
      OccluUtil.spreadUpperandLowerArch(
        occlusionMesh.prevMesh.upper.actor,
        occlusionMesh.prevMesh.lower.actor
      );
      const [rendererL, rendererR] = multipleRenderers;
      rendererL.removeAllActors();
      rendererR.removeAllActors();
      rendererL.addActor(occlusionMesh.prevMesh.upper.actor);
      rendererL.addActor(occlusionMesh.prevMesh.lower.actor);
      rendererL.updateLightsGeometryToFollowCamera();
      rendererL.getActiveCamera().setParallelProjection(true);
      OccluUtil.setCameraforSpreadMesh(rendererL);

      OccluUtil.spreadUpperandLowerArch(
        occlusionMesh.postMesh.upper.actor,
        occlusionMesh.postMesh.lower.actor
      );

      rendererR.addActor(occlusionMesh.postMesh.upper.actor);
      rendererR.addActor(occlusionMesh.postMesh.lower.actor);
      rendererR.updateLightsGeometryToFollowCamera();
      rendererR.getActiveCamera().setParallelProjection(true);
      OccluUtil.setCameraforSpreadMesh(rendererR);

      genericRenderWindowL.resize();
      genericRenderWindowR.resize();
      rendererL.resetCamera();
      rendererR.resetCamera();
      genericRenderWindowL.getRenderWindow().render();
      genericRenderWindowR.getRenderWindow().render();

      setIsLoading(false);
    }
  }, [
    occlusionMesh,
    computationState,
    multipleRenderers,
    genericRenderWindowL,
    genericRenderWindowR,
  ]);

  useEffect(() => {
    if (
      vtkContainerRefL.current &&
      vtkContainerRefR.current &&
      selectScanIds.length >= 2
    ) {
      initOcculusionMesh();
    }
  }, [selectScanIds, initOcculusionMesh]);

  useEffect(() => {
    if (
      occlusionMesh != null &&
      wasmWorker1Ready &&
      wasmWorker2Ready &&
      !computationState.busy &&
      !computationState.done
    ) {
      setComputationState({ busy: true, done: false });
      handleOcclusionMapping(occlusionMesh);
    }
  }, [
    occlusionMesh,
    wasmWorker1Ready,
    wasmWorker2Ready,
    handleOcclusionMapping,
    computationState,
  ]);

  useEffect(() => {
    if (
      vtkContainerRefL.current &&
      vtkContainerRefR.current &&
      occlusionMesh != null &&
      computationState.done
    ) {
      initRenderWindow();
      renderCanvas();
    }
  }, [
    occlusionMesh,
    computationState,
    initRenderWindow,
    renderCanvas,
    vtkContainerRefL,
    vtkContainerRefR,
  ]);

  useEffect(() => {
    if (genericRenderWindowL && genericRenderWindowR) {
      genericRenderWindowL.resize();
      genericRenderWindowR.resize();
      genericRenderWindowL.getRenderWindow().render();
      genericRenderWindowR.getRenderWindow().render();
    }
  }, [isCommentModeActive, genericRenderWindowL, genericRenderWindowR]);

  const chooseOtherMesh = (index: chooseIndex) => {
    setChooseIndex(index);
    setSelectDialogOpened(true);
  };

  return (
    <>
      <StyledLayout>
        <ViewportContainer
          ref={vtkContainerRefL}
          $isCommentModeActive={isCommentModeActive}
          $shiftedContentWithCommentMode={shiftedContentWithCommentMode}
        />
        <ViewportContainer
          ref={vtkContainerRefR}
          $isCommentModeActive={isCommentModeActive}
          $shiftedContentWithCommentMode={shiftedContentWithCommentMode}
        />
      </StyledLayout>
      {genericRenderWindowL && genericRenderWindowR && (
        <GenericToolBar
          multipleGenericRenderWindows={[
            genericRenderWindowL,
            genericRenderWindowR,
          ]}
          multipleRenderers={multipleRenderers}
          withTrueColor={false}
          withViewOrientation={false}
          withComments={true}
        />
      )}
      <DualViewDivider
        $isCommentModeActive={isCommentModeActive}
        $shiftedContentWithCommentMode={shiftedContentWithCommentMode}
      />
      {occlusionMesh && (
        <DualViewLabels
          $isCommentModeActive={isCommentModeActive}
          $shiftedContentWithCommentMode={shiftedContentWithCommentMode}
        >
          <DateWrapper>
            <ColorIndicator
              $readOnly
              $color={occlusionMesh.prevMesh.preferColorHex || "#ffffff"}
              $isActive={true}
            />
            <span>
              {formatDateFromString(occlusionMesh.prevMesh.acquisitionDate)}
            </span>
            <img
              src={ResetPNG}
              alt="reset"
              onClick={() => chooseOtherMesh(0)}
            />
          </DateWrapper>
          <DateWrapper>
            <ColorIndicator
              $readOnly
              $color={occlusionMesh.postMesh.preferColorHex || "#ffffff"}
              $isActive={true}
            />
            <span>
              {formatDateFromString(occlusionMesh.postMesh.acquisitionDate)}
            </span>
            <img
              src={ResetPNG}
              alt="reset"
              onClick={() => chooseOtherMesh(1)}
            />
          </DateWrapper>
        </DualViewLabels>
      )}
      {!isCommentModeActive && <OcclusionProximityRuler />}
      {!isLoading && (
        <CommentPanel
          genericRenderWindows={[genericRenderWindowL, genericRenderWindowR]}
        />
      )}
      <BusyDlg
        open={computationState.busy || isLoading}
        title={translations["CALC_OCCLUSION"]}
      />
      {selectDialogOpened && (
        <DialogBox
          groupedMeshes={groupedMeshes}
          onClose={() => setSelectDialogOpened(false)}
          onConfirm={handleDialogConfirm}
        />
      )}
    </>
  );
};

export default OcclusionProximity;
