import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import {
  DateTimeDisplay,
  TimelineWrapper,
  TimelineItem,
  TimelineLine,
  TimelineCursor,
  ButtonContainer,
  ActionButton,
} from "./TimeLine.styled";
import {
  AppGlobalDataContext,
  AnimationState,
} from "../../../providers/AppGlobalDataProvider";
import {
  parseDateTimeString,
  formatDateForTimeline,
} from "../../../utils/dateTime";
import EclipseIcon from "../../svg-icons/EclipseIcon";
import { NormalMesh } from "../../../model/Mesh";
import vtkGenericRenderWindow from "@kitware/vtk.js/Rendering/Misc/GenericRenderWindow";
import animationCursor from "../../../images/animation-cursor.png";
import Tooltip from "../Tooltip/Tooltip";
import PlayIcon from "../../svg-icons/PlayIcon";
import PauseIcon from "../../svg-icons/PauseIcon";
import StopIcon from "../../svg-icons/StopIcon";

const animationFPS = 25;
const transitionDuration = 3;
const scansMinDistance = 0.035;

export enum TimeLineMode {
  Animation,
  Selection,
}

interface TimelineItemData {
  scanId: string;
  acquisitionDateTime: Date;
  color: string;
  meshes: NormalMesh[];
  relativePosition: number;
}

class TransitionItemData {
  startRelativePosition: number = 0.0;
  endRelativePosition: number = 0.0;
  startMeshes: NormalMesh[] = [];
  endMeshes: NormalMesh[] = [];
}

const calculateRelativePosition = (
  index: number,
  data: TimelineItemData[]
): number => {
  const startDate = data[0].acquisitionDateTime;
  const endDate = data[data.length - 1].acquisitionDateTime;
  const currentDate = data[index].acquisitionDateTime;

  const totalDuration = endDate.getTime() - startDate.getTime();
  const currentDuration = currentDate.getTime() - startDate.getTime();

  return currentDuration / totalDuration;
};

const computeTransitionData = (timelineData: TimelineItemData[]) => {
  let transitions: TransitionItemData[] = [];
  for (let index = 0; index < timelineData.length - 1; ++index) {
    transitions.push({
      startRelativePosition: timelineData[index].relativePosition,
      startMeshes: timelineData[index].meshes,
      endRelativePosition: timelineData[index + 1].relativePosition,
      endMeshes: timelineData[index + 1].meshes,
    });
  }
  return transitions;
};

export const Timeline: React.FC<{
  genericRenderWindow: vtkGenericRenderWindow | null;
  mode: TimeLineMode;
  onSelectionChanged: (meshId1: string, meshId2: string) => void | null;
}> = ({ genericRenderWindow, mode, onSelectionChanged }) => {
  const {
    groupedMeshesByDicomId,
    animationState,
    isCommentModeActive,
    setAnimationState,
    translations,
  } = useContext(AppGlobalDataContext);
  const [animationProgress, setAnimationProgress] = useState(0.0);
  const animationInterval = 1000 / (animationFPS - 1);
  const intervalRef = useRef(0);
  const [showPlayButton, setShowPlayButton] = useState(true);
  const [currentDraggingCursor, setCurrentDraggingCursor] = useState<0 | 1 | 2>(
    0
  );
  const [cursorPos, setCursorPos] = useState<[number, number]>([0, 0]);
  const [relativePositionCache, setRelativePositionCache] = useState<number>(0);

  const timelineData = useMemo(() => {
    let data = Object.values(groupedMeshesByDicomId ?? {})
      .filter((el) => el.isActive)
      .map(({ meshes }) => ({
        scanId: meshes[0]?.scanId || "",
        acquisitionDateTime: parseDateTimeString(
          meshes[0]?.acquisitionDateTime || ""
        ),
        color: meshes[0]?.preferColorHex || "#000000",
        meshes: meshes,
        relativePosition: 0.5,
      }))
      .sort((a, b) => (a.acquisitionDateTime < b.acquisitionDateTime ? -1 : 1));
    if (mode === TimeLineMode.Selection) {
      data = data.filter(
        (item) =>
          item.meshes.find((mesh) => mesh.isUpperJaw()) &&
          item.meshes.find((mesh) => mesh.isLowerJaw())
      );
    }
    return data;
  }, [groupedMeshesByDicomId, mode]);

  // Compute relative position to full timeline for each scan
  timelineData.forEach((item, index) => {
    item.relativePosition = calculateRelativePosition(index, timelineData);
  });

  // Fix scans overlapping
  let isOverlappingFixed = false;
  let currentIteration = 0; // Let's limit number of iteration to avoid infinite loop
  while (!isOverlappingFixed && currentIteration < 100) {
    isOverlappingFixed = true;
    for (let index = 1; index < timelineData.length - 1; index++) {
      const currentPosition = timelineData[index].relativePosition;
      if (currentPosition <= 0.5) {
        const prevPosition = timelineData[index - 1].relativePosition;
        const distance = currentPosition - prevPosition;
        if (distance < scansMinDistance - Number.EPSILON) {
          timelineData[index].relativePosition =
            prevPosition + scansMinDistance;
          isOverlappingFixed = false;
          break;
        }
      } else {
        const nextPosition = timelineData[index + 1].relativePosition;
        const distance = nextPosition - currentPosition;
        if (distance < scansMinDistance - Number.EPSILON) {
          timelineData[index].relativePosition =
            nextPosition - scansMinDistance;
          isOverlappingFixed = false;
          break;
        }
      }
    }
    ++currentIteration;
  }

  // Compute all transitions
  const transitionData = useMemo(
    () => computeTransitionData(timelineData),
    [timelineData]
  );

  // Init selection
  const [selectedItem1, setSelectedItem1] = useState<TimelineItemData | null>(
    null
  );
  const [selectedItem2, setSelectedItem2] = useState<TimelineItemData | null>(
    null
  );

  useEffect(() => {
    if (timelineData.length >= 2) {
      const item1 = timelineData[timelineData.length - 2];
      const item2 = timelineData[timelineData.length - 1];
      setSelectedItem1(item1);
      setSelectedItem2(item2);
      onSelectionChanged(item1.scanId, item2.scanId);
    }
  }, [timelineData, setSelectedItem1, setSelectedItem2, onSelectionChanged]);

  const onEventDragStart1 = () => {
    setCurrentDraggingCursor(1);
    setRelativePositionCache(selectedItem1!.relativePosition);
  };

  const onEventDragStart2 = () => {
    setCurrentDraggingCursor(2);
    setRelativePositionCache(selectedItem2!.relativePosition);
  };

  function onEventDragEnd(event: any) {
    if (currentDraggingCursor === 0) return;

    const dragEvent = event as DragEvent;
    const cursorXPos = dragEvent.clientX;
    const cursorYPos = dragEvent.clientY;

    handleSelect(cursorXPos, cursorYPos);
  }

  function onEventTouchMove(event: any) {
    setCursorPos([event.touches[0].clientX, event.touches[0].clientY]);
    // update icon
    const firstEle = document.getElementById(`${timelineData[0].scanId}`);
    const lastEle = document.getElementById(
      `${timelineData[timelineData.length - 1].scanId}`
    );
    if (firstEle && lastEle) {
      const startPos = firstEle.getBoundingClientRect().x;
      const endPos = lastEle.getBoundingClientRect().x;
      const relativePosition =
        (event.touches[0].clientX - startPos) / (endPos - startPos);

      if (currentDraggingCursor === 1) {
        setSelectedItem1((item) => ({
          ...(item as TimelineItemData),
          relativePosition,
        }));
      }
      if (currentDraggingCursor === 2) {
        setSelectedItem2((item) => ({
          ...(item as TimelineItemData),
          relativePosition,
        }));
      }
    }
  }

  function onEventTouchEnd() {
    handleSelect(...cursorPos);
  }

  function handleSelect(cursorXPos: number, cursorYPos: number) {
    let selectedItemIndex = -1;
    let minDistance = -1;
    // Browse all timeline items
    timelineData.forEach((item, index) => {
      const element = document.getElementById(`${item.scanId}`);
      if (element) {
        // get item screen position
        const itemXPos = element.getBoundingClientRect().x;
        const itemYPos = element.getBoundingClientRect().y;
        const currentItemDistance = Math.sqrt(
          Math.pow(cursorXPos - itemXPos, 2) +
            Math.pow(cursorYPos - itemYPos, 2)
        );
        if (currentItemDistance < 150) {
          if (selectedItemIndex < 0) {
            selectedItemIndex = index;
            minDistance = currentItemDistance;
          } else {
            if (currentItemDistance < minDistance) {
              selectedItemIndex = index;
              minDistance = currentItemDistance;
            }
          }
        }
      }
    });

    if (selectedItemIndex >= 0) {
      const item = timelineData[selectedItemIndex];
      if (
        item.scanId !== selectedItem1?.scanId &&
        item.scanId !== selectedItem2?.scanId
      ) {
        switch (currentDraggingCursor) {
          case 1:
            setSelectedItem1(item);
            onSelectionChanged(item.scanId, selectedItem2!.scanId);
            break;
          case 2:
            setSelectedItem2(item);
            onSelectionChanged(selectedItem1!.scanId, item.scanId);
            break;
        }
      } else {
        resetRelativePosition();
      }
    } else {
      resetRelativePosition();
    }
    setCurrentDraggingCursor(0);
  }

  function resetRelativePosition() {
    switch (currentDraggingCursor) {
      case 1:
        setSelectedItem1((item1) => ({
          ...(item1 as TimelineItemData),
          relativePosition: relativePositionCache,
        }));
        break;
      case 2:
        setSelectedItem2((item2) => ({
          ...(item2 as TimelineItemData),
          relativePosition: relativePositionCache,
        }));
        break;
    }
  }

  const playAnimation = () => {
    if (timelineData.length < 2) return;
    setAnimationState(AnimationState.Playing);
    setShowPlayButton(false);
  };

  const pauseAnimation = () => {
    setAnimationState(AnimationState.Paused);
  };

  const stopAnimation = useCallback(async () => {
    setAnimationState(AnimationState.Stopped);
    setAnimationProgress(0.0);
    setShowPlayButton(true);
  }, [setAnimationState]);

  useEffect(() => {
    if (animationState === AnimationState.Playing) {
      intervalRef.current = window.setInterval(() => {
        if (animationProgress > 1.0) {
          stopAnimation();
        } else {
          // Update animation
          // First, set all mesh opacity to 0
          timelineData.forEach((data) => {
            data.meshes.forEach((mesh) => {
              mesh.setOpacity(0.0);
            });
          });

          // Browse all transitions
          transitionData.forEach((item) => {
            // Update meshes opacity only for meshes concerned by current transition
            if (
              item &&
              animationProgress >= item.startRelativePosition &&
              animationProgress <= item.endRelativePosition
            ) {
              const localTotalProgress =
                item.endRelativePosition - item.startRelativePosition;
              const localRelativeProgress =
                (animationProgress - item.startRelativePosition) /
                localTotalProgress;

              const startOpacity =
                localRelativeProgress < 0.5
                  ? 1.0
                  : 1.0 - (Math.min(0.75, localRelativeProgress) - 0.5) / 0.25;
              const endOpacity =
                localRelativeProgress < 0.5 ? localRelativeProgress : 1.0;

              item.startMeshes.forEach((mesh) => {
                mesh.setOpacity(startOpacity * 100);
              });

              item.endMeshes.forEach((mesh) => {
                mesh.setOpacity(endOpacity * 100);
              });

              // Update current animation progress value
              const progressStep =
                localTotalProgress / (transitionDuration * animationFPS);
              setAnimationProgress((progress) => progress + progressStep);
            }
          });

          if (genericRenderWindow) {
            genericRenderWindow.getRenderWindow().render();
          }
        }
      }, animationInterval);
    }

    return () => {
      window.clearInterval(intervalRef.current);
    }; // clear interval when unmounting the component
  }, [
    genericRenderWindow,
    timelineData,
    transitionData,
    animationState,
    animationProgress,
    animationInterval,
    stopAnimation,
  ]);

  return (
    <>
      {!isCommentModeActive && (
        <>
          {mode === TimeLineMode.Animation && (
            <ButtonContainer>
              <Tooltip title={translations["PLAY_ANIMATION"]}>
                <ActionButton
                  $show={showPlayButton}
                  onClick={playAnimation}
                  disabled={timelineData.length < 2}
                >
                  <PlayIcon />
                </ActionButton>
              </Tooltip>
              <ActionButton $show={!showPlayButton} onClick={stopAnimation}>
                <StopIcon />
              </ActionButton>
              <Tooltip
                title={
                  animationState === AnimationState.Paused
                    ? translations["PLAY_ANIMATION"]
                    : ""
                }
              >
                <ActionButton
                  $show={!showPlayButton}
                  onClick={() => {
                    animationState === AnimationState.Playing
                      ? pauseAnimation()
                      : playAnimation();
                  }}
                >
                  {animationState === AnimationState.Playing ? (
                    <PauseIcon />
                  ) : (
                    <PlayIcon />
                  )}
                </ActionButton>
              </Tooltip>
            </ButtonContainer>
          )}
          <TimelineWrapper>
            <TimelineLine />
            {timelineData.map((item, index) => {
              const isFirstOrLast =
                index === 0 || index === timelineData.length - 1;
              // Determine if this is the first item of the *month* in the year
              const isFirstOfMonth =
                index === 0 ||
                item.acquisitionDateTime.getMonth() !==
                  timelineData[index - 1]?.acquisitionDateTime.getMonth() ||
                item.acquisitionDateTime.getFullYear() !==
                  timelineData[index - 1]?.acquisitionDateTime.getFullYear();
              // Determine if this is the first item of the *year*
              const isFirstOfYear =
                index === 0 ||
                item.acquisitionDateTime.getFullYear() !==
                  timelineData[index - 1]?.acquisitionDateTime.getFullYear();

              return (
                <TimelineItem
                  id={item.scanId}
                  key={index}
                  $relativePosition={item.relativePosition}
                  $isFirstOrLast={isFirstOrLast}
                >
                  <EclipseIcon color={item.color} />
                  <DateTimeDisplay color={item.color}>
                    <span className="date">
                      {
                        formatDateForTimeline(
                          item.acquisitionDateTime,
                          isFirstOfMonth,
                          isFirstOfYear
                        ).date
                      }
                    </span>
                    <span className="year">
                      {
                        formatDateForTimeline(
                          item.acquisitionDateTime,
                          isFirstOfMonth,
                          isFirstOfYear
                        ).year
                      }
                    </span>
                  </DateTimeDisplay>
                </TimelineItem>
              );
            })}
            {mode === TimeLineMode.Animation &&
              animationState !== AnimationState.Stopped && (
                <TimelineCursor $relativePosition={animationProgress}>
                  <img src={animationCursor} alt="Cursor" />
                </TimelineCursor>
              )}
            {mode === TimeLineMode.Selection && (
              <>
                {selectedItem1 && (
                  <TimelineCursor
                    $relativePosition={selectedItem1.relativePosition}
                    onDragStart={onEventDragStart1}
                    onDragEnd={onEventDragEnd}
                    onTouchStart={onEventDragStart1}
                    onTouchMove={onEventTouchMove}
                    onTouchEnd={onEventTouchEnd}
                  >
                    <img src={animationCursor} alt="Cursor" />
                  </TimelineCursor>
                )}
                {selectedItem2 && (
                  <TimelineCursor
                    $relativePosition={selectedItem2.relativePosition}
                    onDragStart={onEventDragStart2}
                    onDragEnd={onEventDragEnd}
                    onTouchStart={onEventDragStart2}
                    onTouchMove={onEventTouchMove}
                    onTouchEnd={onEventTouchEnd}
                  >
                    <img src={animationCursor} alt="Cursor" />
                  </TimelineCursor>
                )}
              </>
            )}
          </TimelineWrapper>
        </>
      )}
    </>
  );
};

export default Timeline;
