import vtkActor from "@kitware/vtk.js/Rendering/Core/Actor";

export default class SliceViewerHandler {
  private renderer: any;
  private sliceActors = new Array<vtkActor>();

  getRenderer() {
    return this.renderer;
  }

  setRenderer(args: any) {
    this.renderer = args;
  }

  addSliceActor(actor: vtkActor) {
    this.sliceActors.push(actor);
  }

  getSliceActors() {
    return this.sliceActors;
  }

  deleteSliceActors() {
    for (let actor of this.sliceActors) {
      this.renderer?.removeActor(actor);
    }
    this.sliceActors = [];
  }

  private log(msg: any) {
    console.log("[SliceViewerHandler]", msg);
  }
}
