import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AppGlobalDataContext } from "../../providers/AppGlobalDataProvider";
import GenericToolBar from "../shared/GenericToolBar/GenericToolBar";
import { NormalMesh, RenderingMode } from "../../model/Mesh";
import { vtkUtil } from "../../utils/vtkUtils";
import WasmWorkerClient from "../../workers/WasmWorkerClient";
import { WasmCmdType } from "../../utils/OccluUtil";
import distanceMapping from "../../utils/DistanceMapping";
import { StyledLayout } from "../../components/layouts/DistanceMapping.styled";
import { ViewOrientation } from "../../utils/common";
import vtkActor from "@kitware/vtk.js/Rendering/Core/Actor";
import vtkGenericRenderWindow from "@kitware/vtk.js/Rendering/Misc/GenericRenderWindow";
import DistanceMappingLegend from "../../model/DistanceMapping/DistanceMappingLegend";
import { Timeline, TimeLineMode } from "../shared/TimeLine/TimeLine";
import BusyDlg from "../shared/BusyDlg/BusyDlg";
import UpperLowerSwitch from "../shared/UpperLowerSwitch/UpperLowerSwitch";
import CommentPanel from "../shared/Comment/CommentPanel";
import vtkInteractorStyleMeshView from "../../vtkExtension/InteractorStyleMeshView";

export const DistanceMapping: React.FC = () => {
  const vtkContainerRef = useRef(null);
  const [computationFinish, setComputationFinish] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [wasmWorkerReady, setWasmWorkerReady] = useState<boolean>(false);
  const [secondWasmWorkerReady, setSecondWasmWorkerReady] =
    useState<boolean>(false);
  const [genericRenderWindow, setGenericRenderWindow] =
    useState<vtkGenericRenderWindow>();
  const [cameraOrientation, setCameraOrientation] = useState<
    ViewOrientation | 0
  >(ViewOrientation.Front);
  const [upperActorDistanceMap, setUpperActorDistanceMap] =
    useState<vtkActor | null>(null);
  const [lowerActorDistanceMap, setLowerActorDistanceMap] =
    useState<vtkActor | null>(null);
  const [distanceMappingReady, setDistanceMappingReady] =
    useState<boolean>(false);
  const [computationTriggered, setComputationTriggered] =
    useState<boolean>(false);
  const [upperVisible, setUpperVisible] = useState<boolean>(true);
  const [lowerVisible, setLowerVisible] = useState<boolean>(true);
  const wasmWorker1 = useRef<WasmWorkerClient | null>(null);
  const wasmWorker2 = useRef<WasmWorkerClient | null>(null);
  const {
    isCommentModeActive,
    groupedMeshesByDicomId,
    translations,
    comments,
  } = useContext(AppGlobalDataContext);
  const [selectedMeshes, setSelectedMeshes] = useState<NormalMesh[]>();

  const interactorStyleSuppressRotate = useRef(
    vtkInteractorStyleMeshView.newInstance()
  );

  // Initialize WebAssembly Workers
  useEffect(() => {
    wasmWorker1.current = new WasmWorkerClient();
    wasmWorker1.current.onmessage = (e) => {
      if (WasmCmdType.InitWasmSuccess === e?.data) {
        setWasmWorkerReady((prev) => prev || true);
      }
    };
    wasmWorker1.current.postMessage({ type: WasmCmdType.InitWasm });
    wasmWorker2.current = new WasmWorkerClient();
    wasmWorker2.current.onmessage = (e) => {
      if (WasmCmdType.InitWasmSuccess === e?.data) {
        setSecondWasmWorkerReady((prev) => prev || true);
      }
    };
    wasmWorker2.current.postMessage({ type: WasmCmdType.InitWasm });
  }, []);

  useEffect(() => {
    if (
      wasmWorker1.current &&
      wasmWorker2.current &&
      wasmWorkerReady &&
      secondWasmWorkerReady
    ) {
      distanceMapping.setWorker(wasmWorker1.current, wasmWorker2.current);
      setDistanceMappingReady(true);
    }
  }, [wasmWorkerReady, secondWasmWorkerReady]);

  const handleDistanceMapComputation = useCallback(
    async (meshesToCompute: NormalMesh[]) => {
      // Classify meshes into upper and lower jaws
      const upperJawMeshes: NormalMesh[] = [];
      const lowerJawMeshes: NormalMesh[] = [];
      for (const mesh of meshesToCompute) {
        const matchedMesh = mesh;
        matchedMesh.actor.getProperty().setOpacity(1.0);
        matchedMesh.setRenderingMode(RenderingMode.Scalar);
        if (matchedMesh.isUpperJaw()) {
          upperJawMeshes.push(matchedMesh);
        } else {
          lowerJawMeshes.push(matchedMesh);
        }
      }
      // Ensure there are at least two meshes in each category
      if (upperJawMeshes.length === 2 && lowerJawMeshes.length === 2) {
        setIsLoading(true);
        distanceMapping
          .compute(
            { first: upperJawMeshes[0], second: upperJawMeshes[1] },
            { first: lowerJawMeshes[0], second: lowerJawMeshes[1] }
          )
          .then(({ upperActor, lowerActor }) => {
            setUpperActorDistanceMap(upperActor);
            setLowerActorDistanceMap(lowerActor);
            setComputationFinish(true);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("Error during distance mapping computation:", error);
            setIsLoading(false);
          });
      } else {
        console.error("Insufficient meshes for computation");
      }
    },
    []
  );

  // Init render window
  useEffect(() => {
    if (genericRenderWindow === undefined) {
      const renderWindow = vtkUtil.createGenericRenderWindow(vtkContainerRef);
      renderWindow
        ?.getInteractor()
        ?.setInteractorStyle(interactorStyleSuppressRotate.current);
      setGenericRenderWindow(renderWindow);
    }
  }, [genericRenderWindow, vtkContainerRef]);

  // Update data
  useEffect(() => {
    if (
      !genericRenderWindow ||
      !computationFinish ||
      !upperActorDistanceMap ||
      !lowerActorDistanceMap
    ) {
      return;
    }
    const renderer = genericRenderWindow.getRenderer();

    renderer.addActor(upperActorDistanceMap);
    renderer.addActor(lowerActorDistanceMap);
    upperActorDistanceMap.setVisibility(upperVisible);
    lowerActorDistanceMap.setVisibility(lowerVisible);

    renderer.resetCamera();
    genericRenderWindow.resize();
    genericRenderWindow.getRenderWindow().render();
  }, [
    genericRenderWindow,
    computationFinish,
    upperActorDistanceMap,
    lowerActorDistanceMap,
    upperVisible,
    lowerVisible,
  ]);

  useEffect(() => {
    if (!genericRenderWindow || !cameraOrientation) {
      return;
    }
    const renderer = genericRenderWindow.getRenderer();
    const cam = renderer.getActiveCamera();
    vtkUtil.setCameraOrientation(cam, cameraOrientation);
    renderer.getActiveCamera().setParallelProjection(true);
    renderer.resetCamera();
    genericRenderWindow.resize();
  }, [genericRenderWindow, cameraOrientation]);

  useEffect(() => {
    if (distanceMappingReady && selectedMeshes && !computationTriggered) {
      setComputationTriggered(true);
      handleDistanceMapComputation(selectedMeshes);
    }
  }, [
    selectedMeshes,
    distanceMappingReady,
    computationTriggered,
    handleDistanceMapComputation,
  ]);

  useEffect(() => {
    upperActorDistanceMap?.setVisibility(upperVisible);
    lowerActorDistanceMap?.setVisibility(lowerVisible);

    if (genericRenderWindow === undefined) return;
    const picker = genericRenderWindow.getInteractor().getPicker();

    // Update picker data for comments
    if (picker) {
      picker.initializePickList();
      comments.forEach((comment) => {
        picker.addPickList(comment.actor);
      });
      if (upperVisible && upperActorDistanceMap)
        picker.addPickList(upperActorDistanceMap);
      if (lowerVisible && lowerActorDistanceMap)
        picker.addPickList(lowerActorDistanceMap);
    }
  }, [
    upperVisible,
    lowerVisible,
    upperActorDistanceMap,
    lowerActorDistanceMap,
    genericRenderWindow,
    comments,
  ]);

  const handleTimeLineSelectionChanged = useCallback(
    (meshId1: string, meshId2: string) => {
      if (!genericRenderWindow) return;
      const renderer = genericRenderWindow.getRenderer();
      // reset actors and remove them from the scene
      setUpperActorDistanceMap(null);
      setLowerActorDistanceMap(null);
      renderer.removeAllActors();
      renderer.removeAllViewProps();

      const selectedMeshes = Object.entries(groupedMeshesByDicomId || {})
        .filter(([dicomId]) => dicomId === meshId1 || dicomId === meshId2)
        .flatMap(([_, { meshes }]) => meshes)
        .sort(
          (a, b) =>
            Number(b.acquisitionDateTime) - Number(a.acquisitionDateTime)
        );

      setComputationTriggered(false);
      setComputationFinish(false);
      setSelectedMeshes(selectedMeshes);
    },
    [
      genericRenderWindow,
      groupedMeshesByDicomId,
      setSelectedMeshes,
      setComputationTriggered,
      setComputationFinish,
    ]
  );

  return (
    <div>
      <StyledLayout ref={vtkContainerRef} />
      <GenericToolBar
        cameraOrientation={cameraOrientation}
        setCameraOrientation={setCameraOrientation}
        genericRenderWindow={genericRenderWindow || null}
        withTrueColor={false}
        withViewOrientation={true}
        withComments={true}
      />
      {!isLoading && (
        <CommentPanel genericRenderWindows={[genericRenderWindow]} />
      )}
      <Timeline
        genericRenderWindow={genericRenderWindow || null}
        mode={TimeLineMode.Selection}
        onSelectionChanged={handleTimeLineSelectionChanged}
      />
      <UpperLowerSwitch
        upperVisible={upperVisible}
        lowerVisible={lowerVisible}
        upperVisibleChange={() => {
          setUpperVisible(!upperVisible);
        }}
        lowerVisibleChange={() => {
          setLowerVisible(!lowerVisible);
        }}
      />
      {!isCommentModeActive && <DistanceMappingLegend />}
      <BusyDlg open={isLoading} title={translations["CALC_DISTANCE_MAPPING"]} />
    </div>
  );
};

export default DistanceMapping;
