import React, { useContext, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  ButtonsContainer,
  StyledNavLink,
  ExportButon,
} from "./ModeMenuPanel.styled";
import occlusionProximityImage from "../../../images/occlusion_proximity.png";
import distanceMappingImage from "../../../images/distance-mapping.png";
import measurementImage from "../../../images/measurement.png";
import pointBasedMatchingImage from "../../../images/point-based-matching.png";
import exportImage from "../../../images/export.png";
import Tooltip from "../Tooltip/Tooltip";
import ExportDialog from "../ExportDialog/ExportDialog";
import { AppGlobalDataContext } from "../../../providers/AppGlobalDataProvider";

export const ModeMenuPanel: React.FC = () => {
  const {
    groupedMeshesByDicomId,
    translations,
    isCommentModeActive,
    setIsCommentModeActive,
  } = useContext(AppGlobalDataContext);
  const { pathname } = useLocation();
  const [isExportActive, setIsExportActive] = useState(false);

  const numberOfActiveScans: number = useMemo(
    () =>
      !groupedMeshesByDicomId
        ? 0
        : Object.entries(groupedMeshesByDicomId).filter(
            ([, { isActive }]) => isActive
          ).length,
    [groupedMeshesByDicomId]
  );

  const numberOfActiveFullScans: number = useMemo(
    () =>
      !groupedMeshesByDicomId
        ? 0
        : Object.entries(groupedMeshesByDicomId).filter(
            ([, { meshes, isActive }]) => meshes.length >= 2 && isActive
          ).length,
    [groupedMeshesByDicomId]
  );

  const closeCommentPanel = () => {
    if (isCommentModeActive) {
      setIsCommentModeActive(false);
    }
  };

  const handleExport = () => {
    closeCommentPanel();
    setIsExportActive((prevState) => !prevState);
  };

  const onNavLinkClick = (e: React.MouseEvent<HTMLElement>, path: string) => {
    closeCommentPanel();
    if (
      (path === "measurement" && numberOfActiveScans < 2) ||
      (path !== "measurement" && numberOfActiveFullScans < 2)
    ) {
      e.preventDefault();
    }
  };

  return (
    <>
      <ButtonsContainer data-html2canvas-ignore>
        <Tooltip title={translations["DISTANCE_MAPPING"]}>
          <StyledNavLink
            to={pathname !== "/distance-mapping" ? "/distance-mapping" : "/"}
            className={pathname === "/distance-mapping" ? "active" : ""}
            onClick={(e) => onNavLinkClick(e, "distance-mapping")}
            $disabled={numberOfActiveFullScans < 2}
          >
            <img src={distanceMappingImage} alt="Distance Mapping" />
          </StyledNavLink>
        </Tooltip>
        <Tooltip title={translations["MEASUREMENT"]}>
          <StyledNavLink
            to={pathname !== "/measurement" ? "/measurement" : "/"}
            className={pathname === "/measurement" ? "active" : ""}
            onClick={(e) => onNavLinkClick(e, "measurement")}
            $disabled={numberOfActiveScans < 2}
          >
            <img src={measurementImage} alt="Measurement" />
          </StyledNavLink>
        </Tooltip>
        <Tooltip title={translations["OCCLUSION_EVOLUTION"]}>
          <StyledNavLink
            to={
              pathname !== "/occlusion-proximity" ? "/occlusion-proximity" : "/"
            }
            className={pathname === "/occlusion-proximity" ? "active" : ""}
            onClick={(e) => onNavLinkClick(e, "occlusion-proximity")}
            $disabled={numberOfActiveFullScans < 2}
          >
            <img src={occlusionProximityImage} alt="Occlusion Proximity" />
          </StyledNavLink>
        </Tooltip>
        <Tooltip title={translations["MATCHING"]}>
          <StyledNavLink
            to={pathname !== "/point-matching" ? "/point-matching" : "/"}
            className={pathname === "/point-matching" ? "active" : ""}
            onClick={(e) => onNavLinkClick(e, "point-matching")}
            $disabled={numberOfActiveFullScans < 2}
          >
            <img src={pointBasedMatchingImage} alt="Matching" />
          </StyledNavLink>
        </Tooltip>
        <Tooltip title={translations["SAVE"]}>
          <ExportButon
            className={isExportActive ? "active" : ""}
            onClick={handleExport}
          >
            <img src={exportImage} alt="Export" />
          </ExportButon>
        </Tooltip>
      </ButtonsContainer>

      {isExportActive && (
        <ExportDialog
          onClose={() => setIsExportActive(false)}
          withSaveAndStay={true}
        />
      )}
    </>
  );
};

export default ModeMenuPanel;
