import { ReactComponent as LowerJawIcon } from "../../../images/lower_small.svg";
import { ReactComponent as UpperJawIcon } from "../../../images/upper_small.svg";
import { IconButton, JawsPanel } from "./UpperLowerSwith.styled";

interface IUpperLowerSwitchProps {
  upperVisible: boolean;
  upperVisibleChange: () => void;
  lowerVisible: boolean;
  lowerVisibleChange: () => void;
  isUpperEnabled?: boolean;
  isLowerEnabled?: boolean;
}

const UpperLowerSwitch: React.FC<IUpperLowerSwitchProps> = ({
  upperVisible,
  upperVisibleChange,
  lowerVisible,
  lowerVisibleChange,
  isUpperEnabled = true,
  isLowerEnabled = true,
}) => {
  return (
    <JawsPanel>
      <IconButton
        onClick={upperVisibleChange}
        $isEnabled={isUpperEnabled}
        $isActive={upperVisible}
        disabled={!isUpperEnabled}
        aria-pressed={upperVisible}
        aria-label="Show Upper Jaw"
      >
        <UpperJawIcon />
      </IconButton>
      <IconButton
        onClick={lowerVisibleChange}
        $isEnabled={isLowerEnabled}
        $isActive={lowerVisible}
        disabled={!isLowerEnabled}
        aria-pressed={lowerVisible}
        aria-label="Show Lower Jaw"
      >
        <LowerJawIcon />
      </IconButton>
    </JawsPanel>
  );
};

export default UpperLowerSwitch;
