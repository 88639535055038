import React, { useCallback, useContext, useMemo, useState } from "react";
import {
  IconButton,
  StyledHeader,
  StyledVersionDialog,
  UserGuideContainer,
  VersionContainer,
  VersionDialogTitle,
} from "./Header.styled";

import { AppConfigContext } from "../../../providers/AppConfigProvider";
import { AppGlobalDataContext } from "../../../providers/AppGlobalDataProvider";
import logo from "../../../images/app-logo.png";
import InformationIcon from "../../svg-icons/InformationIcon";
import PowerIcon from "../../svg-icons/PowerIcon";
import Tooltip from "../Tooltip/Tooltip";
import HelpIcon from "../../svg-icons/HelpIcon";
import { Document, Page, pdfjs } from "react-pdf";
import ExportDialog from "../ExportDialog/ExportDialog";

// Set up pdfjs worker using a CDN
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const Header: React.FC = () => {
  const config = useContext(AppConfigContext);
  const [openVersionDialog, setOpenVersionDialog] = useState(false);
  const [isExitPromptActive, setIsExitPromptActive] = useState(false);
  const { qtContext, patient, capturedSnapshots, translations, locale } =
    useContext(AppGlobalDataContext);
  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [numPages, setNumPages] = useState<number | null>(null);

  const handleOnAppClose = useCallback(() => {
    if (capturedSnapshots.length && capturedSnapshots.some((el) => !el.saved)) {
      setIsExitPromptActive(true);
    } else {
      if (qtContext) {
        qtContext.quit();
      } else {
        window && window.close();
      }
    }
  }, [qtContext, capturedSnapshots]);

  const getUserGuideFile = useMemo(() => {
    const defaultFile = require(
      `../../../resources/UserGuide_en_US.pdf`
    ).default;
    if (!locale) {
      return defaultFile;
    }
    try {
      return require(`../../../resources/UserGuide_${locale}.pdf`).default;
    } catch (error) {
      console.error(
        `Could not load user guide for locale ${locale}, falling back to default.`
      );
      return defaultFile;
    }
  }, [locale]);

  const OpenGuide = useCallback(() => {
    setOpenPdfViewer(true);
  }, []);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <StyledHeader>
      <img src={logo} alt="App logo" />
      <span>{patient?.name}</span>
      <Tooltip title={translations["HELP"]}>
        <IconButton onClick={OpenGuide} data-html2canvas-ignore>
          <HelpIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={translations["ABOUT"]}>
        <IconButton
          onClick={() => setOpenVersionDialog(true)}
          data-html2canvas-ignore
        >
          <InformationIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title={translations["CLOSE"]}>
        <IconButton onClick={handleOnAppClose} data-html2canvas-ignore>
          <PowerIcon />
        </IconButton>
      </Tooltip>

      <StyledVersionDialog
        open={openVersionDialog}
        onClose={() => setOpenVersionDialog(false)}
      >
        <VersionContainer>
          <VersionDialogTitle>{translations["ABOUT"]}</VersionDialogTitle>
          <img src={logo} alt="App logo" />
          <span>
            {translations["PRODUCT_VERSION"]}: {config?.buildVersion}
          </span>
          <p>
            &copy;2024, Dental Imaging Technologies Corporation. All right
            reserved.
          </p>
        </VersionContainer>
      </StyledVersionDialog>

      {openPdfViewer && (
        <StyledVersionDialog
          open={openPdfViewer}
          onClose={() => setOpenPdfViewer(false)}
        >
          <UserGuideContainer>
            <VersionDialogTitle>
              Oral Compare {translations["USER_GUIDE"]}
            </VersionDialogTitle>

            <div className="pdf-viewer">
              <Document
                file={getUserGuideFile}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    width={1100}
                    className="pdf-page"
                  />
                ))}
              </Document>
            </div>
            <button
              onClick={() => setOpenPdfViewer(false)}
              className="close-button"
            >
              {translations["CLOSE"]}
            </button>
          </UserGuideContainer>
        </StyledVersionDialog>
      )}
      {isExitPromptActive && (
        <ExportDialog onClose={() => setIsExitPromptActive(false)} />
      )}
    </StyledHeader>
  );
};
