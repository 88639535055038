import vtkActor from "@kitware/vtk.js/Rendering/Core/Actor";
import vtkCellPicker from "@kitware/vtk.js/Rendering/Core/CellPicker";
import vtkRenderer from "@kitware/vtk.js/Rendering/Core/Renderer";
import {
  RGBColor,
  Vector3,
  vtkPipelineConnection,
} from "@kitware/vtk.js/types";
import { TriView } from "../../utils/vtkUtils";
import vtkSphereSource from "@kitware/vtk.js/Filters/Sources/SphereSource";
import vtkMapper from "@kitware/vtk.js/Rendering/Core/Mapper";
import vtkRenderWindow from "@kitware/vtk.js/Rendering/Core/RenderWindow";
import { matrix4 } from "../../utils/matrixUtils";
import { NormalMesh } from "../Mesh";
import { computeManualMatchingTransform } from "../../utils/manualMatcher";
import { mat4 } from "gl-matrix";

enum RendererTarget {
  LEFT_TOP = 0,
  LEFT_BOTTOM = 1,
  Right = 2,
}
const pointColors: RGBColor[] = [
  [0.0, 1.0, 0.502], //"#00FF80"
  [0.102, 0.776, 1.0], //"#1AC6FF"
  [1.0, 0.302, 1.0], //"#FF4DFF"
  [0.208, 0.796, 0.753], //"#35CCC0"
  [0.6, 0.667, 1.0], //"#99AAFF"
  [1.0, 0.2, 0.533], //"#FF3388"
];

type PickedPoint = {
  position: Vector3;
  target: RendererTarget;
  actor: vtkActor;
  transActor: vtkActor; // actor in Main Renderer
  source: vtkSphereSource;
};
type FuncStatus = {
  apply: boolean;
  restore: boolean;
  reset: boolean;
};
const maxPoints = 6;
export class PickPointHandler {
  private rendererLT!: vtkRenderer;
  private rendererLB!: vtkRenderer;
  private rendererMain!: vtkRenderer;
  private renderWindow!: vtkRenderWindow;
  private picker: vtkCellPicker;
  private pickActorLT!: vtkActor;
  private pickActorLB!: vtkActor;
  private pickedPoints: Array<PickedPoint>;
  private matchingAvailable: (status: FuncStatus) => void = () => {};
  private matchingCallback: (matrix: matrix4) => void = () => {};
  private grabbedIndex: number;
  private grabbedColor: RGBColor;
  private mousePressPos: Vector3 = [0, 0, 0];
  private mouseReleasePos: Vector3 = [0, 0, 0];
  private transActorMatrix: matrix4;
  private pokedRenderer: vtkRenderer | null = null;

  constructor() {
    this.grabbedIndex = -1;
    this.grabbedColor = [1.0, 0, 0];
    this.pickedPoints = [];
    this.picker = vtkCellPicker.newInstance();
    this.picker.setPickFromList(1);
    this.picker.setTolerance(0);
    this.picker.initializePickList();
    // init transform matrix from LeftBottom to Main
    const identity = mat4.create();
    this.transActorMatrix = Array.prototype.slice.call(identity) as matrix4;
  }

  setRenderers(triview: TriView) {
    this.rendererLT = triview.rendererLT;
    this.rendererLB = triview.rendererLB;
    this.rendererMain = triview.rendererMain;
    this.renderWindow = triview.renderWindow.getRenderWindow();
  }

  setPickActorLT(actor: vtkActor) {
    this.pickActorLT = actor;
  }

  setPickActorLB(actor: vtkActor) {
    this.pickActorLB = actor;
  }

  setPokedRenderer(renderer: vtkRenderer) {
    this.pokedRenderer = renderer;
  }

  setMouseReleasePos(position: Vector3) {
    this.mouseReleasePos = position;
  }

  setMatchingCallback(cb: (data: matrix4) => void) {
    this.matchingCallback = cb;
  }

  setMatchingAvailable(cb: (data: FuncStatus) => void) {
    this.matchingAvailable = cb;
  }

  resetGrabbed() {
    this.grabbedIndex = -1;
    this.grabbedColor = [1.0, 0, 0];
  }

  pickPoint(position: Vector3, renderer: vtkRenderer): boolean {
    this.mousePressPos = position;
    // add visible actors into PickList
    let target: RendererTarget;
    if (renderer === this.rendererLT) {
      target = RendererTarget.LEFT_TOP;
    } else if (renderer === this.rendererLB) {
      target = RendererTarget.LEFT_BOTTOM;
    } else {
      return false;
    }
    this.picker.initializePickList();
    this.pickedPoints
      .filter((pt) => pt.target === target)
      .forEach((pt) => {
        this.picker.addPickList(pt.actor);
      });
    this.picker.pick(position, renderer);
    if (this.picker.getActors().length > 0) {
      // grab sphere
      this.grabbedIndex = this.pickedPoints.findIndex(
        (pt) => pt.actor === this.picker.getActors()[0]
      );
      this.grabbedColor = this.pickedPoints[this.grabbedIndex].actor
        .getProperty()
        .getColor();
      this.pickedPoints[this.grabbedIndex].actor
        .getProperty()
        .setColor([1, 0, 0]);
      // hide transActor
      this.pickedPoints[this.grabbedIndex].transActor.setVisibility(false);
      return true;
    }
    return false;
  }

  addPoint() {
    if (!this.pokedRenderer) return;
    const renderer = this.pokedRenderer;
    const position = this.mouseReleasePos;
    if (this.grabbedIndex >= 0) {
      // pick on mesh
      const grabbedPoint = this.pickedPoints[this.grabbedIndex];
      if (renderer === this.rendererLT) {
        this.picker.initializePickList();
        this.picker.addPickList(this.pickActorLT);
      } else if (renderer === this.rendererLB) {
        this.picker.initializePickList();
        this.picker.addPickList(this.pickActorLB);
      }

      this.picker.pick(position, renderer);
      if (this.picker.getActors().length > 0) {
        const pickedPoint = this.picker.getPickedPositions()[0];
        grabbedPoint.source.setCenter(...pickedPoint);
        grabbedPoint.source.update();
        grabbedPoint.position = pickedPoint;
        this.renderWindow.render();
      } else {
        // flap out
        this.pickedPoints[this.grabbedIndex].source.setCenter(
          this.pickedPoints[this.grabbedIndex].position
        );
      }
      // recover grabbed point
      grabbedPoint.source.setRadius(0.5);
      grabbedPoint.actor.getProperty().setColor(this.grabbedColor);
      // show transActor
      grabbedPoint.transActor.setVisibility(true);
      this.renderWindow.render();
      this.resetGrabbed();
      return;
    }
    if (this.pointsDistance(this.mousePressPos, position) > 25) return;
    let target = RendererTarget.LEFT_TOP;
    if (renderer === this.rendererLT) {
      this.picker.initializePickList();
      this.picker.addPickList(this.pickActorLT);
    } else if (renderer === this.rendererLB) {
      target = RendererTarget.LEFT_BOTTOM;
      this.picker.initializePickList();
      this.picker.addPickList(this.pickActorLB);
    } else {
      return;
    }
    this.picker.pick(position, renderer);
    if (this.picker.getActors().length > 0) {
      const pickedPoint = this.picker.getPickedPositions()[0];
      // create new sphere
      this.drawSphere(pickedPoint, target);
    }
    return;
  }

  createSphereActor(position: Vector3) {
    const source = vtkSphereSource.newInstance();
    source.setCenter(...position);
    source.setThetaResolution(20);
    source.setPhiResolution(20);
    source.update();
    const actor = this.createActor(source.getOutputPort());
    return { source, actor };
  }

  createActor(output: vtkPipelineConnection) {
    const mapper = vtkMapper.newInstance();
    mapper.setInputConnection(output);
    const actor = vtkActor.newInstance();
    actor.setMapper(mapper);

    actor.setVisibility(true);
    return actor;
  }

  drawSphere(position: Vector3, target: RendererTarget) {
    let renderer: vtkRenderer;
    let count = 0;
    if (target === RendererTarget.LEFT_TOP) {
      renderer = this.rendererLT;
      count = this.pickedPoints.filter(
        (pt) => pt.target === RendererTarget.LEFT_TOP
      ).length;
    } else {
      renderer = this.rendererLB;
      count = this.pickedPoints.filter(
        (pt) => pt.target === RendererTarget.LEFT_BOTTOM
      ).length;
    }
    if (count >= maxPoints) return;
    const { source, actor } = this.createSphereActor(position);
    // set color by index
    actor.getProperty().setColor(pointColors[count]);
    renderer?.addActor(actor);
    const transActor = this.createActor(source.getOutputPort());
    transActor.getProperty().setColor(pointColors[count]);
    if (target === RendererTarget.LEFT_BOTTOM) {
      transActor.setUserMatrix(this.transActorMatrix);
    }
    this.rendererMain.addActor(transActor);
    this.pickedPoints.push({
      position,
      target,
      actor,
      transActor,
      source,
    });
    this.renderWindow.render();
    const status = this.updateMatchingAvailable();
    this.matchingAvailable(status);
  }

  updateSphere(position: Vector3, renderer: vtkRenderer) {
    if (this.grabbedIndex < 0) return;
    const grabbedPoint = this.pickedPoints[this.grabbedIndex];
    const view = this.renderWindow.getViews()[0];
    const cameraPos = renderer.getActiveCamera().getPosition();
    const center = view.displayToWorld(position[0], position[1], 0, renderer);
    grabbedPoint.source.setCenter(center);
    // offset scale
    const radius =
      0.5 /
      Math.sqrt(
        this.pointsDistance(grabbedPoint.position, cameraPos) /
          this.pointsDistance(center, cameraPos)
      );
    grabbedPoint.source.setRadius(radius);
    this.renderWindow.render();
  }

  restorePoint() {
    const restorePoint = this.pickedPoints.pop();
    if (!restorePoint) return;
    if (restorePoint.target === RendererTarget.LEFT_TOP) {
      this.rendererLT.removeActor(restorePoint.actor);
    } else {
      this.rendererLB.removeActor(restorePoint.actor);
    }
    this.rendererMain.removeActor(restorePoint.transActor);
    const status = this.updateMatchingAvailable();
    this.matchingAvailable(status);
    this.renderWindow.render();
  }
  resetPoints() {
    if (0 === this.pickedPoints.length) return;
    this.pickedPoints.forEach((restorePoint) => {
      if (restorePoint.target === RendererTarget.LEFT_TOP) {
        this.rendererLT.removeActor(restorePoint.actor);
      } else {
        this.rendererLB.removeActor(restorePoint.actor);
      }
      this.rendererMain.removeActor(restorePoint.transActor);
    });
    this.pickedPoints = [];
    this.matchingAvailable({ apply: false, restore: false, reset: false });
    this.renderWindow.render();
  }

  updateMatchingAvailable(): FuncStatus {
    const pointsLT = this.pickedPoints.filter(
      (pt) => pt.target === RendererTarget.LEFT_TOP
    );
    const pointsLB = this.pickedPoints.filter(
      (pt) => pt.target === RendererTarget.LEFT_BOTTOM
    );
    const apply = pointsLT.length === pointsLB.length && pointsLB.length >= 3;
    const restore = pointsLT.length > 0 || pointsLB.length > 0;
    const reset = restore;
    return { apply, restore, reset };
  }

  performMatching(movingMesh: NormalMesh, targetMesh: NormalMesh) {
    const pointsLT = this.pickedPoints.filter(
      (pt) => pt.target === RendererTarget.LEFT_TOP
    );
    const pointsLB = this.pickedPoints.filter(
      (pt) => pt.target === RendererTarget.LEFT_BOTTOM
    );
    if (pointsLT.length !== pointsLB.length || pointsLB.length < 3) return;
    const createPointPairs = (
      pointsLT: PickedPoint[],
      pointsLB: PickedPoint[]
    ): number[] => {
      const pointPairs: number[] = [];
      pointsLT.forEach((pointLT, index) => {
        const pointLB = pointsLB[index];
        pointPairs.push(
          pointLB.position[0], // points of moving mesh
          pointLB.position[1],
          pointLB.position[2],
          pointLT.position[0], // points of target mesh
          pointLT.position[1],
          pointLT.position[2]
        );
      });
      return pointPairs;
    };
    const pointPairs = createPointPairs(pointsLT, pointsLB);

    computeManualMatchingTransform(movingMesh, targetMesh, pointPairs).then(
      (matrix) => {
        // matrix is sotred as Row major by the algorithm side (Unlike vtk Transform which is column major),
        // so no need to perfrom matrix transposition
        const matArray = Array.prototype.slice.call(matrix) as matrix4;
        this.renderWindow.render();
        const newTransMatArray = this.multiplyInvertTransform(
          matArray,
          this.transActorMatrix
        );
        // transform points
        this.updateTransActorMatrix(matArray);
        this.matchingCallback(newTransMatArray);
        this.matchingAvailable({ apply: true, restore: true, reset: true });
      }
    );
  }

  multiplyInvertTransform(matrix: matrix4, preTransMat: mat4 | undefined) {
    if (!preTransMat) preTransMat = mat4.create();
    const invPreMat = mat4.create();
    mat4.invert(invPreMat, preTransMat);
    const newTransMat = mat4.create();
    mat4.multiply(newTransMat, invPreMat, matrix);
    const newTransMatArray = Array.prototype.slice.call(newTransMat) as matrix4;
    return newTransMatArray;
  }

  updateTransActorMatrix(matrix: matrix4) {
    this.transActorMatrix = matrix;
    const userMatrix = mat4.create();
    mat4.transpose(userMatrix, matrix);
    const pointsLB = this.pickedPoints.filter(
      (pt) => pt.target === RendererTarget.LEFT_BOTTOM
    );
    pointsLB.forEach((pt) => {
      pt.transActor.setUserMatrix(userMatrix);
      pt.transActor.modified();
    });
  }

  pointsDistance(pos1: Vector3, pos2: Vector3) {
    return (
      (pos1[0] - pos2[0]) * (pos1[0] - pos2[0]) +
      (pos1[1] - pos2[1]) * (pos1[1] - pos2[1]) +
      (pos1[2] - pos2[2]) * (pos1[2] - pos2[2])
    );
  }
}
