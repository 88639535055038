import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  StyledCheckbox,
  CheckboxTitleLabel,
  OptionsContainer,
  OptionLabel,
  ThumbnailContainer,
  SnapshotWrapper,
  VideoWrapper,
  CheckboxOverlay,
  GenerateButton,
  Backdrop,
  Dialog,
  Title,
  VideoThumbnailImg,
  SnapshotThumbnailImg,
  ButtonsContainer,
  SaveAndExitButton,
  CancelButtton,
  SaveButtton,
  EmptyBoxContainer,
  TextContainer,
} from "./ExportDialog.styled";
import thumbnailPlaceholderImg from "../../../images/video-thumbnail-placeholder.png";
import { AppGlobalDataContext } from "../../../providers/AppGlobalDataProvider";
import EmptyBox from "../../svg-icons/EmptyBox";
import ExclamationBubbleIcon from "../../svg-icons/ExclamationBubbleIcon";

interface ExportDialogProps {
  withSaveAndStay?: boolean;
  onClose: () => void;
}

const ExportDialog: React.FC<ExportDialogProps> = ({
  onClose,
  withSaveAndStay = false,
}) => {
  const { capturedSnapshots, setCapturedSnapshots, qtContext, translations } =
    useContext(AppGlobalDataContext);
  const [checkedSnapshotsMap, setCheckedSnapshotsMap] = useState<{
    [key: string]: { base64: string; checked: boolean; saved: boolean };
  }>({});
  const [pdfReport, setPdfReport] = useState(true);
  const [snapshotSeparately, setSnapshotSeparately] = useState(false);
  const [video, setVideo] = useState(false);

  useEffect(() => {
    const mappedSnapshots: {
      [key: string]: { base64: string; checked: boolean; saved: boolean };
    } = {};
    capturedSnapshots.forEach(({ snapshot, saved }, index) => {
      mappedSnapshots[index] = {
        base64: snapshot,
        checked: saved,
        saved: saved,
      };
    });
    setCheckedSnapshotsMap(mappedSnapshots);
  }, [capturedSnapshots]);

  const isThereSnapshotsToSave: boolean = useMemo(() => {
    return (
      !!capturedSnapshots.length &&
      Object.values(checkedSnapshotsMap).some(
        (value) => value.checked && !value.saved
      )
    );
  }, [capturedSnapshots, checkedSnapshotsMap]);

  const handleSnapshotChange = (key: string) => {
    setCheckedSnapshotsMap((prev) => {
      const newMap = { ...prev };
      newMap[key].checked = !newMap[key].checked;
      return newMap;
    });
  };

  const handleSelectAllSnapshots = (isChecked: boolean) => {
    setCheckedSnapshotsMap((prevState) => {
      const newMap: {
        [key: string]: { base64: string; checked: boolean; saved: boolean };
      } = { ...prevState };
      for (let key in prevState) {
        newMap[key].checked = isChecked;
      }
      return newMap;
    });
  };

  const handleSelectAllVideos = (checked: boolean) => {
    setVideo(checked);
  };

  const handleOnSave = async () => {
    if (qtContext) {
      for (let { base64, checked, saved } of Object.values(
        checkedSnapshotsMap
      )) {
        if (checked && !saved) {
          await qtContext.saveSnapshot(base64);
          setCapturedSnapshots((prevState) => {
            const newState = [...prevState];
            const snapshot = newState.find((el) => el.snapshot === base64);
            snapshot && (snapshot.saved = true);
            return newState;
          });
        }
      }
    }
    onClose();
  };

  const handleOnSaveAndExit = async () => {
    if (qtContext) {
      for (let { base64, checked, saved } of Object.values(
        checkedSnapshotsMap
      )) {
        if (checked && !saved) {
          await qtContext?.saveSnapshot(base64);
        }
      }
      qtContext?.quit();
    } else {
      window && window.close();
    }
  };

  return (
    <Backdrop>
      <Dialog>
        <Title>{translations["SAVE"]}</Title>
        {capturedSnapshots.length ? (
          <>
            <CheckboxTitleLabel>
              <StyledCheckbox
                checked={
                  !!capturedSnapshots.length &&
                  Object.values(checkedSnapshotsMap).every(
                    (value) => value.checked
                  )
                }
                onChange={(e) => handleSelectAllSnapshots(e.target.checked)}
              />
              {translations["SNAPSHOT"]}
            </CheckboxTitleLabel>

            <ThumbnailContainer>
              {Object.entries(checkedSnapshotsMap).map(
                ([key, { base64, checked, saved }]) => (
                  <SnapshotWrapper key={key}>
                    <SnapshotThumbnailImg src={base64} />
                    <CheckboxOverlay
                      checked={checked || saved}
                      onChange={() => handleSnapshotChange(key)}
                      disabled={saved}
                    />
                  </SnapshotWrapper>
                )
              )}
              <OptionsContainer style={{ display: "none" }}>
                <OptionLabel>
                  <StyledCheckbox
                    checked={pdfReport}
                    onChange={() => setPdfReport(!pdfReport)}
                  />
                  Save snapshot in PDF Report
                </OptionLabel>
                <OptionLabel>
                  <StyledCheckbox
                    checked={snapshotSeparately}
                    onChange={() => setSnapshotSeparately(!snapshotSeparately)}
                  />
                  {translations["SAVE_SNAPSHOT_SEPERATELY"]}
                </OptionLabel>
              </OptionsContainer>
            </ThumbnailContainer>

            <CheckboxTitleLabel style={{ display: "none" }}>
              <StyledCheckbox
                checked={video}
                onChange={(e) => handleSelectAllVideos(e.target.checked)}
              />
              {translations["VIDEO"]}
            </CheckboxTitleLabel>

            <ThumbnailContainer style={{ display: "none" }}>
              <VideoWrapper>
                <VideoThumbnailImg src={thumbnailPlaceholderImg} />
                <GenerateButton onClick={() => setVideo(!video)}>
                  {video ? "Remove Video" : "Generate"}
                </GenerateButton>
              </VideoWrapper>
            </ThumbnailContainer>
          </>
        ) : (
          <EmptyBoxContainer>
            <EmptyBox />
            <TextContainer>
              <ExclamationBubbleIcon />
              <p>{translations["ITS_EMPTY_SNAPSHOTS"]}</p>
            </TextContainer>
          </EmptyBoxContainer>
        )}

        <ButtonsContainer>
          <CancelButtton onClick={() => onClose()}>
            {translations["CANCEL"]}
          </CancelButtton>
          {withSaveAndStay && (
            <>
              <SaveButtton
                disabled={!isThereSnapshotsToSave}
                onClick={handleOnSave}
              >
                {translations["SAVE"]}
              </SaveButtton>
              <SaveAndExitButton
                disabled={!isThereSnapshotsToSave}
                onClick={handleOnSaveAndExit}
              >
                {translations["SAVE_AND_EXIT"]}
              </SaveAndExitButton>
            </>
          )}
          {!withSaveAndStay && (
            <SaveAndExitButton onClick={handleOnSaveAndExit}>
              {!isThereSnapshotsToSave
                ? translations["EXIT"]
                : translations["SAVE_AND_EXIT"]}
            </SaveAndExitButton>
          )}
        </ButtonsContainer>
      </Dialog>
    </Backdrop>
  );
};

export default ExportDialog;
