import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const ToBeStyledTooltip = ({ className, id, ...props }: any) => (
  <Tooltip {...props} classes={{ tooltip: className }} id={id} />
);

export const StyledTooltip = styled(ToBeStyledTooltip)(() => ({
  backgroundColor: "#FFF79A80",
  color: "black",
  border: "2px solid #AFAFAF20",
  borderRadius: "0px",
  fontSize: "14px",
  fontWeight: "400",
  paddingInlineStart: "11px",
  padding: "6px 12px",
}));
