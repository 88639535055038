import macro from "@kitware/vtk.js/macros";
import vtkInteractorStyleTrackballCamera from "@kitware/vtk.js/Interaction/Style/InteractorStyleTrackballCamera";
import { States } from "@kitware/vtk.js/Rendering/Core/InteractorStyle/Constants";
// ----------------------------------------------------------------------------
// vtkInteractorStyleMatching methods
// ----------------------------------------------------------------------------

function vtkInteractorStyleMatching(publicAPI: any, model: any) {
  //   // Set our className
  model.classHierarchy.push("vtkInteractorStyleMatching");
  // Public API methods
  //----------------------------------------------------------------------------

  const handleLeftButtonPressSuper = publicAPI.handleLeftButtonPress;
  const handleMouseMoveSuper = publicAPI.handleMouseMove;
  publicAPI.handleLeftButtonPress = (callData: any) => {
    if (model.normalMode) {
      handleLeftButtonPressSuper(callData);
    } else {
      if (model.panRotateMode) {
        const pos = callData.position;
        model.previousPosition = pos;
        if (callData.shiftKey) {
          if (callData.controlKey || callData.altKey) {
            publicAPI.startDolly();
          } else {
            publicAPI.startPan();
          }
        } else {
          if (callData.controlKey || callData.altKey) {
            publicAPI.startSpin();
          } else {
            publicAPI.startRotate();
          }
        }
      }
    }
  };

  publicAPI.handleMouseMove = (callData: any) => {
    if (model.normalMode) {
      handleMouseMoveSuper(callData);
    } else {
      const pos = callData.position;
      const renderer = callData.pokedRenderer;
      switch (model.state) {
        case States.IS_ROTATE:
          if (model.panRotateMode) {
            publicAPI.handleMouseRotate(renderer, pos);
            publicAPI.invokeInteractionEvent({
              type: "InteractionEvent",
            });
          }
          break;
        case States.IS_PAN:
          publicAPI.handleMousePan(renderer, pos);
          publicAPI.invokeInteractionEvent({
            type: "InteractionEvent",
          });
          break;
        case States.IS_DOLLY:
          publicAPI.handleMouseDolly(renderer, pos);
          publicAPI.invokeInteractionEvent({
            type: "InteractionEvent",
          });
          break;
        case States.IS_SPIN:
          publicAPI.handleMouseSpin(renderer, pos);
          publicAPI.invokeInteractionEvent({
            type: "InteractionEvent",
          });
          break;
      }
      model.previousPosition = pos;
    }
  };

  publicAPI.handleRightButtonPress = () => {
    publicAPI.startPan();
  };

  publicAPI.handleRightButtonRelease = () => {
    publicAPI.endPan();
  };

  publicAPI.isPanOrRotate = () => {
    return States.IS_ROTATE === model.state || States.IS_PAN === model.state;
  };
}

// ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {
  normalMode: false,
  panRotateMode: true,
};

// ----------------------------------------------------------------------------

export function extend(publicAPI: any, model: any, initialValues: any) {
  Object.assign(model, DEFAULT_VALUES, initialValues);

  // Inheritance
  vtkInteractorStyleTrackballCamera.extend(publicAPI, model, initialValues);

  // Create get-set macros
  macro.setGet(publicAPI, model, ["normalMode", "panRotateMode"]);

  // For more macro methods, see "Sources/macros.js"

  // Object specific methods
  vtkInteractorStyleMatching(publicAPI, model);
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(
  extend,
  "vtkInteractorStyleMatching"
);

// ----------------------------------------------------------------------------
// eslint-disable-next-line
export default { newInstance };
