import React from "react";

const PlayIcon: React.FC = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34 18C34 26.8366 26.8366 34 18 34C9.16344 34 2 26.8366 2 18C2 9.16344 9.16344 2 18 2C26.8366 2 34 9.16344 34 18ZM36 18C36 27.9411 27.9411 36 18 36C8.05887 36 0 27.9411 0 18C0 8.05887 8.05887 0 18 0C27.9411 0 36 8.05887 36 18ZM15.0952 9.18165C14.4353 8.69279 13.5 9.1639 13.5 9.98521V26.0148C13.5 26.8361 14.4353 27.3072 15.0952 26.8183L25.9152 18.8036C26.4549 18.4038 26.4549 17.5962 25.9152 17.1964L15.0952 9.18165Z"
        fill="#4D4D4D"
      />
    </svg>
  );
};

export default PlayIcon;
