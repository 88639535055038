import styled, { keyframes } from "styled-components";
import { colors } from "../../../theme";

export const TimelineWrapper = styled.div`
  position: absolute;
  bottom: 40px;
  left: 44%;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: calc(100vw - 720px);
  padding: 10px 0;
  border-radius: 5px;
  transform: translateX(-50%);
`;

export const TimelineLine = styled.div`
  position: absolute;
  bottom: 28px;
  left: 0;
  right: 0;
  height: 4px;
  background: ${colors["gray-1"]};
`;

export const TimelineItem = styled.div<{
  $relativePosition: number;
  $isFirstOrLast: boolean;
}>`
  position: absolute;
  left: ${({ $relativePosition }) => `${$relativePosition * 100}%`};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ $isFirstOrLast }) => ($isFirstOrLast ? "16px" : "auto")};
  transform: translateX(-50%);
  z-index: 1;
`;

const ButtonBase = styled.button<ButtonProps>`
  position: ${({ $show }) => ($show ? "" : "absolute")};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  padding: 0;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  box-shadow: none;
  outline: none;
  transition:
    transform 0.2s ease-in-out,
    opacity 0.2s ease-in-out;
  cursor: pointer;
  z-index: ${({ $show }) => ($show ? "10" : "1")};

  & img {
    width: 100%;
  }

  &:active {
    transform: scale(0.9);
  }

  &:disabled {
    pointer-events: none;

    & img,
    svg {
      opacity: 0.4;
    }
  }
`;

interface ButtonProps {
  $show?: boolean;
  $relativePosition?: number;
}

const fadeInAnimation = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOutAnimation = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

export const ButtonContainer = styled.div`
  position: fixed;
  bottom: 44px;
  right: calc(100vw - 200px);
  display: flex;
  gap: 20px;
  padding: 8px 18px;
  border: 2px solid ${colors["gray-2"]};
  border-radius: 30px;
  background-color: white;
`;

export const ActionButton = styled(ButtonBase)<ButtonProps>`
  animation: ${({ $show }) => ($show ? fadeInAnimation : fadeOutAnimation)} 0.3s
    forwards;
`;

export const DateTimeDisplay = styled.div<{ color: string }>`
  font-size: 14px;
  margin-top: 8px;
  white-space: pre-wrap;
  text-align: center;
  font-weight: 700;

  & .date {
    color: ${(props) => props.color};
  }

  & .year {
    color: ${colors["dark-gray"]};
  }
`;

export const TimelineCursor = styled(ButtonBase)<{
  $relativePosition: number;
}>`
  position: absolute;
  left: calc(
    ${({ $relativePosition }) => `${$relativePosition * 100}%`} - 15px
  );
  bottom: 54px;
  width: 30px;
`;
