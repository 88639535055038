import styled, { keyframes, css } from "styled-components";
import { colors } from "../../../theme";

const snapshotAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  15% {
    transform: scale(0.5);
  }
  60% {
    opacity: 1;
    transform: scale(0.5);
  }
  70% {
    left: 0;
    top: 0;
    transform: scale(0.1);
  }
  100% {
    opacity: 0;
    left: -50%;
    top: 140px;
    transform: scale(0.1);
  }
`;

export const ButtonsContainer = styled.div`
  position: fixed;
  top: 120px;
  right: 0;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  background-color: transparent;
  z-index: 10;

  & button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 62px;
    height: 62px;
    border: 2px solid ${colors["gray-1"]};
    background-color: white;
    cursor: pointer;
    outline: none;

    & img {
      pointer-events: none;
    }

    &:hover:not(.active):not(.activeView) {
      background-color: ${colors["blue-2"]};
    }
  }

  & button.toggleMenuVisibility {
    width: 40px;
  }

  & button.active {
    background-color: ${colors["blue-1"]};
    border: 2px solid transparent;
  }

  &.collapsed button:not(:last-child) {
    visibility: hidden;
  }
`;

export const DropdownContainer = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  display: flex;
  flex-direction: row;
  gap: 2px;
  padding: 8px 0;
  z-index: 10;

  &.hidden {
    visibility: hidden;
  }

  & button.activeView {
    background-color: ${colors["blue-1"]};
    border: 2px solid transparent;
  }
`;

export const SnapshotAnimatedImage = styled.img<{ $animate: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  border-radius: 10px;
  box-shadow: 0 0 15px ${colors["gray-1"]};

  ${(props) =>
    props.$animate &&
    css`
      animation: ${snapshotAnimation} 2.2s forwards;
    `}
`;
