import styled from "styled-components";

export const JawsPanel = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
  & > div {
    cursor: pointer;
  }
`;

export const IconButton = styled.button<{
  $isActive: boolean;
  $isEnabled: boolean;
}>`
  background: none;
  border: none;
  padding: 0;
  margin: 8px 0;
  cursor: ${({ $isEnabled }) => ($isEnabled ? "pointer" : "default")};
  opacity: ${({ $isEnabled }) => ($isEnabled ? 1 : 0.5)};
  pointer-events: ${({ $isEnabled }) => ($isEnabled ? "auto" : "none")};
  outline: none;

  & > svg > path:nth-child(2) {
    fill: ${({ $isActive }) => ($isActive ? "#009bb4" : "#ffffff")};
  }
`;
